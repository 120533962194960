"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Is Object Empty ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Object} obj
 * @returns {Object}
 */
function default_1(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}
exports.default = default_1;
