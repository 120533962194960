import select from '@sparkle/js/fn/select/select';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';

/*
 * Puzzle functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Puzzle = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Puzzle.SELECTOR
    });

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        scene: () => select(`${options.selector}__scene`, el),
        media: () => select(`${options.selector}__media`, that.dom.$('scene')),
        mediaImg: () => select('picture', that.dom.$('media')),
        overlayLeft: () => select(`${options.selector}__overlay-left`, that.dom.$('scene')),
        overlayRight: () => select(`${options.selector}__overlay-right`, that.dom.$('scene')),
        overlayTop: () => select(`${options.selector}__overlay-top`, that.dom.$('scene')),
        overlayBottom: () => select(`${options.selector}__overlay-bottom`, that.dom.$('scene')),
        overlayCenter: () => select(`${options.selector}__overlay-center`, that.dom.$('scene'))
    });

    // Init
    that.init();
};

/**
 * Init
 */
Puzzle.prototype.init = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        return;
    };

    // Fct - Vibe
    const vibe = async () => {

        that.vibes.push(new ScrollVibe(that.dom.$('scene'), {

            do: [

                // Pin
                {
                    trigger: that.dom.$('scene'),
                    start: '50% 50%',
                    end: 'bottom top',
                    pin: true,
                    pinFocus: true
                },

                // Hide Overlays
                {
                    target: [that.dom.$('overlayLeft'), that.dom.$('overlayRight'), that.dom.$('overlayCenter')],
                    start: '50% 50%',
                    duration: '90%',
                    parallax: {
                        width: {
                            to: 0,
                            ease: 'power2.in'
                        }
                    }
                },
                {
                    target: [that.dom.$('overlayTop'), that.dom.$('overlayBottom')],
                    start: '50% 50%',
                    duration: '70%',
                    parallax: {
                        height: {
                            to: 0,
                            ease: 'power2.in'
                        }
                    }
                },

                // Tween Media
                {
                    start: '50% 50%',
                    target: that.dom.$('media'),
                    reverse: false,
                    tween: {
                        scale: {
                            from: 1.15,
                            to: 1,
                            speed: 12500,
                            ease: 'power0.in'
                        }
                    }
                },

                // Smooth Scroll
                {
                    target: that.dom.$('mediaImg'),
                    start: 'top bottom',
                    duration: '95%',
                    imageLoaded: true,
                    parallax: {
                        y: {
                            from: '-20%',
                            to: 0,
                            ease: 'power0.in'
                        }
                    }
                },
                {

                    trigger: that.dom.$('scene'),
                    target: that.dom.$('mediaImg'),
                    start: 'bottom 50%',
                    duration: '50%',
                    imageLoaded: true,
                    parallax: {
                        y: {
                            to: '30%',
                            ease: 'power0.in'
                        }
                    }
                }
            ]
        }));

        ScrollVibe.refresh();

        // Listener - Resize
        that.events.on(that.el, 'resize', () => {
            ScrollVibe.refresh();
        });

        return;
    };

    // Init & Proceed
    await init();
    vibe();
};

/**
 * Destroy
 */
Puzzle.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
Puzzle.SELECTOR = '.js-puzzle';

export default Puzzle;
