import getAttr from '@sparkle/js/fn/attributes/getAttr';
import hide from '@sparkle/js/fn/attributes/hide';
import show from '@sparkle/js/fn/attributes/show';
import scrollLock from '@sparkle/js/fn/browser/scrollLock';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Morph from '@sparkle/js/utilities/Morph';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Navigation functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Navigation = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Navigation.SELECTOR
    });

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        toggles: () => selectAll(`${options.selector}__toggle`),
        overlay: () => select(`${options.selector}__overlay`, el),
        sideburger: () => select(`${options.selector}__sideburger`),
        bg: () => select(`${options.selector}__bg`, el),
        bgAnimation: () => select(`${options.selector}__bg-animation`, that.dom.$('bg')),
        wave: () => select('#animation-wave-right'),
        waveFlat: () => select('#animation-wave-right-flat', that.dom.$('wave')),
        waveShape: () => select('#animation-wave-right-bump', that.dom.$('wave')),
        menu: () => select(`${options.selector}__menu`, el),
        menuItems: () => selectAll(`${options.selector}__menu-item`, that.dom.$('menu')),
        close: () => select(`${options.selector}__close`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
Navigation.prototype.init = function () {
    const that = this;

    // Instance
    that.isOpen = false;
    that.isUpdating = false;

    // Fct - Toggle
    const toggle = () => {
        if (!that.isUpdating) {
            if (that.isOpen) {
                that.events.trigger(that.el, 'handleClose');
            } else {
                that.events.trigger(that.el, 'handleOpen');
            }
        }
    };

    // Listener - Close / Handle
    that.events.on(that.el, 'handleClose', () => {
        that.close();
    });

    // Listener - Open / Handle
    that.events.on(that.el, 'handleOpen', () => {
        that.open();
    });

    // Listener - Toggles
    that.events.on(that.dom.$('toggles'), 'click', e => {
        e.preventDefault();
        toggle();
    });

    // Listener - Overlay
    that.events.on(that.dom.$('overlay'), 'click', e => {
        e.preventDefault();
        toggle();
    });
};

/**
 * Open
 */
Navigation.prototype.open = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        that.isOpen = true;
        that.isUpdating = true;
        scrollLock(true);
        hide([that.dom.$('overlay'), that.dom.$('menu')]);
        show(that.el);
        return;
    };

    // Fct - Sideburger -> Hide
    const sideburgerHide = async () => {
        await new Promise(resolve => {
            that.events.once(that.dom.$('sideburger'), 'onHide', () => {
                resolve();
            });
            that.events.trigger(that.dom.$('sideburger'), 'handleHide');
        });
        return;
    };

    // Fct - Bg -> Show
    const bgShow = async () => {

        // Tween
        const t = new Tweener({ speed: 500, ease: 'power2.inOut' })
            .add(that.dom.$('bgAnimation'), {
                scaleX: { from: 0, to: 1 }
            });

        // Morph
        const m = new Morph({ speed: 250, ease: 'sine.inOut' })
            .add(that.dom.$('bgAnimation'), {
                from: that.dom.$('waveFlat'),
                to: that.dom.$('waveShape')
            })
            .add(that.dom.$('bgAnimation'), {
                to: that.dom.$('waveFlat')
            }, true);

        await Promise.all([t.play(), m.play()]);

        return;
    };

    // Fct - Overlay -> Show
    const overlayShow = async () => {
        Tweener.set(that.dom.$('overlay'), { opacity: 0 });
        show(that.dom.$('overlay'));
        await new Tweener({ speed: 400, ease: 'power1.inOut' })
            .add(that.dom.$('overlay'), {
                opacity: { to: 1 }
            })
            .play();
        return;
    };

    // Fct - Menu - Show
    const menuShow = async () => {
        const tweenMenuItems = new Tweener({ speed: 275, ease: 'power3.out' });
        each(that.dom.$('menuItems'), (el, i) => {
            tweenMenuItems.add(el, {
                x: { from: 50, to: 0, delay: 500 + i * 50 },
                opacity: { from: 0, to: 1, delay: 500 + i * 50, ease: 'power1.out' }
            });
        });
        await tweenMenuItems
            .onStart(() => {
                show([that.dom.$('menu'), that.dom.$('menuItems')]);
            })
            .play();

        each(that.dom.$('menuItems'), el => {
            Tweener.clear(el, ['x', 'opacity']);
        });

        return;
    };

    // Fct - Close - Show
    const closeShow = async () => {
        await new Tweener({ speed: 250, delay: 750, ease: 'power2.in' })
            .add(that.dom.$('close'), {
                scaleX: { from: 0, to: 1 },
                x: { from: '-50%', to: 0 }
            })
            .onStart(() => {
                show(that.dom.$('close'));
            })
            .play();

        return;
    };

    // Fct - Done
    const done = async () => {
        that.isUpdating = false;
        that.events.trigger(that.el, 'onOpen');
        return;
    };

    // Init & Proceed
    await init();
    await Promise.all([sideburgerHide(), overlayShow(), bgShow(), menuShow(), closeShow()]);
    done();
};

/**
 * Close
 */
Navigation.prototype.close = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        that.isOpen = false;
        that.isUpdating = true;
        return;
    };

    // Fct - Close - Hide
    const closeHide = async () => {
        await new Tweener({ speed: 250, ease: 'power2.in' })
            .add(that.dom.$('close'), {
                scaleX: { to: 0 },
                x: { to: '-50%' }
            })
            .play();

        Tweener.clear(that.dom.$('close'), ['scaleX', 'x']);
        hide(that.dom.$('close'));
        return;
    };

    // Fct - Menu - Hide
    const menuHide = async () => {

        // Menu Items
        const tweenMenuItems = new Tweener({ speed: 250, ease: 'power3.in' });
        each(that.dom.$('menuItems'), (el, i) => {
            tweenMenuItems.add(el, {
                x: { to: -50, delay: (that.dom.$('menuItems').length - i) * 50 },
                opacity: { to: 0, delay: (that.dom.$('menuItems').length - i) * 50, ease: 'power1.out' }
            });
        });
        await tweenMenuItems.play();

        hide(that.dom.$('menu'));
        each(that.dom.$('menuItems'), el => {
            Tweener.clear(el, ['x', 'opacity']);
        });

        return;
    };

    // Fct - Sideburger -> Shiw
    const sideburgerShow = async () => {
        await new Promise(resolve => {
            that.events.once(that.dom.$('sideburger'), 'onShow', () => {
                resolve();
            });
            that.events.trigger(that.dom.$('sideburger'), 'handleShow');
        });
        return;
    };

    // Fct - Bg -> Hide
    const bgHide = async () => {

        // Paths
        const pathFlat = getAttr(that.dom.$('waveFlat'), 'd');
        const pathWave = getAttr(that.dom.$('waveShape'), 'd');

        // Tween
        const t = new Tweener({ speed: 500, ease: 'power2.inOut' })
            .add(that.dom.$('bgAnimation'), {
                scaleX: { to: 0 }
            });

        // Morph
        const m = new Morph({ speed: 250, ease: 'sine.inOut' })
            .add(that.dom.$('bgAnimation'), {
                from: pathFlat,
                to: pathWave
            })
            .add(that.dom.$('bgAnimation'), {
                to: pathFlat
            }, true);

        await Promise.all([t.play(), m.play()]);

        return;
    };

    // Fct - Overlay -> Hide
    const overlayHide = async () => {
        await new Tweener({ speed: 400, ease: 'power1.inOut' })
            .add(that.dom.$('overlay'), {
                opacity: { to: 0 }
            })
            .play();

        hide(that.dom.$('overlay'));
        return;
    };

    // Fct - Done
    const done = async () => {
        that.isUpdating = false;
        hide(that.el);
        scrollLock(false);
        that.events.trigger(that.el, 'onClose');
        return;
    };

    // Init & Proceed
    await init();
    await Promise.all([closeHide(), menuHide()]);
    await Promise.all([bgHide(), overlayHide(), sideburgerShow()]);
    done();
};

/**
 * Destroy
 */
Navigation.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
Navigation.SELECTOR = '.js-navigation';

export default Navigation;
