import DomManager from './DomManager';
import EventsManager from './EventsManager';
import addClass from '../fn/attributes/addClass';
import swapClass from '../fn/attributes/swapClass';
import select from '../fn/select/select';
import setProps from '../helpers/object/setProps';

/*
 * Check if the device is touchable or not. Reacts to touch events to determine if
 * a device is touchable. Adds 'touch' & 'no-touch' to the document & dispatch
 * events when touchable is defined.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Touchable = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: Touchable.SELECTOR
    });

    // Modifiers
    that.modifiers = {
        vTouch: Touchable.TOUCH,
        vNoTouch: Touchable.NO_TOUCH,
        vWebApp: Touchable.WEB_APP,
        vBrowser: Touchable.BROWSER
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        html: () => select('html', document)
    });

    // Init
    that.init();
};

/**
 * Init
 */
Touchable.prototype.init = function () {
    const that = this;

    // Status
    that.isTouch = false;

    // Detect touch events. Add class to 'html' element. Trigger event
    that.events.once(window, 'touchstart', () => {
        that.isTouch = true;
        swapClass(that.dom.$('html'), that.modifiers.vNoTouch, that.modifiers.vTouch);
        that.events.trigger(document, 'onTouchable');
    });

    // Detect mouseover events. If the device is not already classified as a touch
    // device, it should mean that it is a device with a mouse. Add class to 'html'
    // element. Trigger event
    that.events.once(window, 'mouseover', () => {
        if (!that.isTouch) {
            swapClass(that.dom.$('html'), that.modifiers.vTouch, that.modifiers.vNoTouch);
            that.events.trigger(document, 'onNoTouchable');
        }
    });

    // Detect if the navigator is a web app or a browser
    if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches) {
        addClass(that.dom.$('html'), that.modifiers.vWebApp);
    } else {
        addClass(that.dom.$('html'), that.modifiers.vBrowser);
    }
};

/**
 * Destroy
 */
Touchable.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
Touchable.SELECTOR = '.js-touchable';
Touchable.TOUCH = 'touch';
Touchable.NO_TOUCH = 'no-touch';
Touchable.WEB_APP = 'web-app';
Touchable.BROWSER = 'browser';

export default Touchable;
