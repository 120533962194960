/*
 * App
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 */

// App variables
import App from '@app/js/App';
import Sparkle from '@sparkle/js/Sparkle';

// Plugins - App
import '@app/js/plugins/about';
import '@app/js/plugins/header';
import '@app/js/plugins/hero';
import '@app/js/plugins/indexCarousel';
import '@app/js/plugins/indexHero';
import '@app/js/plugins/indexWelcome';
import '@app/js/plugins/navigation';
import '@app/js/plugins/nitro';
import '@app/js/plugins/puzzle';
import '@app/js/plugins/sideburger';
import '@app/js/plugins/workFilter';
import '@app/js/plugins/workProjectHero';
import '@app/js/plugins/zeus';

// Plugins - Sparkle
import '@sparkle/js/plugins/carousel';
import '@sparkle/js/plugins/carouselNavigation';
import '@sparkle/js/plugins/imageReveal';
import '@sparkle/js/plugins/imageSmooth';
import '@sparkle/js/plugins/mousetrack';
import '@sparkle/js/plugins/touchable';
import '@sparkle/js/plugins/scrollTo';
import '@sparkle/js/plugins/scrollVibe';
import '@sparkle/js/plugins/textReveal';

// Polyfills
import '@sparkle/js/polyfills/imagesResponsive';
import '@sparkle/js/polyfills/lazyload';

// Functions
import domReady from '@sparkle/js/fn/events/domReady';
import each from '@sparkle/js/helpers/collection/each';
import yieldToMain from '@sparkle/js/helpers/function/yieldToMain';
import has from '@sparkle/js/helpers/object/has';
import keys from '@sparkle/js/helpers/object/keys';

// Defaults
const defaults = {
    plugins: {}
};

// Plugins
const plugins = {

    // App
    about: {
        yield: true
    },
    header: {
        yield: false
    },
    hero: {
        yield: false
    },
    indexCarousel: {
        yield: true
    },
    indexHero: {
        yield: false
    },
    indexWelcome: {
        yield: true
    },
    menu: {
        yield: false
    },
    navigation: {
        yield: false
    },
    nitro: {
        yield: true
    },
    puzzle: {
        yield: true
    },
    sideburger: {
        yield: true
    },
    workFilter: {
        yield: true
    },
    workProjectHero: {
        yield: false
    },
    zeus: {
        yield: false
    },

    // Sparkle
    carousel: {
        yield: true
    },
    carouselNavigation: {
        yield: true
    },
    imageReveal: {
        yield: false
    },
    imageSmooth: {
        yield: false
    },
    mousetrack: {
        yield: true
    },
    touchable: {
        yield: false
    },
    scrollTo: {
        yield: false
    },
    scrollVibe: {
        yield: false
    },
    textReveal: {
        yield: false
    }
};

// DOM Ready
await domReady();

// Start App plugins
each(keys(App.plugins), key => {
    if (has(plugins, key) && !plugins[key].yield) {
        App.plugins[key](defaults.plugins[key] || {});
        delete plugins[key];
    }
});

// Start Sparkle plugins
each(keys(Sparkle.plugins), key => {
    if (has(plugins, key) && !plugins[key].yield) {
        Sparkle.plugins[key](defaults.plugins[key] || {});
        delete plugins[key];
    }
});

// Start App plugins - Yield
each(keys(App.plugins), async key => {
    if (has(plugins, key)) {
        await yieldToMain();
        App.plugins[key](defaults.plugins[key] || {});
    }
});

// Start Sparkle plugins - Yield
each(keys(Sparkle.plugins), async key => {
    if (has(plugins, key)) {
        await yieldToMain();
        Sparkle.plugins[key](defaults.plugins[key] || {});
    }
});
