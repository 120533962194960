import addClass from '@sparkle/js/fn/attributes/addClass';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import select from '@sparkle/js/fn/select/select';
import setProps from '@sparkle/js/helpers/object/setProps';
import className from '@sparkle/js/helpers/utils/className';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Header functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Header = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Header.SELECTOR
    });

    // Modifiers
    that.modifiers = {
        vDisabled: 'v--disabled',
        vLight: 'v--light'
    };

    // Selectors
    that.selectors = {
        logo: `${options.selector}__logo`
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Header.prototype.init = function () {
    const that = this;

    // Listener - Disable
    that.events.on(that.el, 'handleDisable', () => {
        that.disable();
    });

    // Listener - Enable
    that.events.on(that.el, 'handleEnable', () => {
        that.enable();
    });

    // Listener - Dark
    that.events.on(that.el, 'handleDarkMode', () => {
        that.darkMode();
    });

    // Listener - Light
    that.events.on(that.el, 'handleLightMode', () => {
        that.lightMode();
    });
};

/**
 * Disable
 */
Header.prototype.disable = function () {
    const that = this;

    addClass(that.el, that.modifiers.vDisabled);

    new Tweener({ speed: 400, ease: 'power2.inOut' })
        .add(select(that.selectors.logo, that.el), {
            scale: { to: 0.5 },
            opacity: { to: 0 }
        })
        .play();
};

/**
 * Enable
 */
Header.prototype.enable = function () {
    const that = this;

    removeClass(that.el, that.modifiers.vDisabled);

    new Tweener({ speed: 700, delay: 100, ease: 'power2.inOut' })
        .add(select(that.selectors.logo, that.el), {
            scale: { from: 0.5, to: 1 },
            opacity: { from: 0, to: 1 }
        })
        .play();
};

/**
 * Light mode
 */
Header.prototype.lightMode = function () {
    const that = this;
    removeClass(select(that.selectors.logo, that.el), that.modifiers.vLight);
};

/**
 * Dark
 */
Header.prototype.darkMode = function () {
    const that = this;
    addClass(select(that.selectors.logo, that.el), that.modifiers.vLight);
};

/**
 * Destroy
 */
Header.prototype.destroy = function () {
    this.events.destroy();
};

/**
 * Constants
 */
Header.SELECTOR = '.js-header';

export default Header;
