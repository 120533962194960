import imagesLoaded from 'imagesloaded';
import onCallback from '../.internal/callback/onCallback';
import getAttr from '../../fn/attributes/getAttr';
import getTag from '../../fn/attributes/getTag';
import eventOnce from '../../fn/events/eventOnce';
import select from '../../fn/select/select';
/*
 * Check whenever an image is fully loaded. It can be a classic load or a lazy load
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default async function (node, options) {
    const that = this;

    await new Promise(resolve => {

        // Select matching node
        node = select(node);

        // The img can either be the node itself or a child
        const nodeImg = getTag(node) === 'img' ? node : select('img', node);

        // Fct - Done
        const done = () => {
            onCallback.call(that, 'onComplete', options);
            resolve();
        };

        if (nodeImg) {

            // Classic load
            if (getAttr(nodeImg, 'src')) {
                imagesLoaded(nodeImg, () => {
                    done();
                });

            // Lazy load
            } else if (getAttr(nodeImg, 'data-src')) {
                eventOnce(nodeImg, 'lazyloaded', () => {
                    done();
                });
            }
        }
    });

    return;
}
