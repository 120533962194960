import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';

/*
 * IndexCarousel functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const IndexCarousel = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: IndexCarousel.SELECTOR
    });

    // Selectors
    that.selectors = {
        slide: `${options.selector}__slide`
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
IndexCarousel.prototype.init = function () {
    const that = this;

    // Fct - Vibe
    each(selectAll(that.selectors.slide, that.el), $slide => {
        that.vibes.push(new ScrollVibe($slide, {
            target: select('picture', $slide),
            do: [
                {
                    start: 'top bottom',
                    end: 'bottom bottom',
                    imageLoaded: true,
                    parallax: {
                        y: {
                            from: '-20%',
                            to: 0,
                            ease: 'power1.out'
                        }
                    }
                }
            ]
        }));
    });

};

/**
 * Destroy
 */
IndexCarousel.prototype.destroy = function () {
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
IndexCarousel.SELECTOR = '.js-indexCarousel';

export default IndexCarousel;
