import addClass from '@sparkle/js/fn/attributes/addClass';
import getAttr from '@sparkle/js/fn/attributes/getAttr';
import hasClass from '@sparkle/js/fn/attributes/hasClass';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import setAttr from '@sparkle/js/fn/attributes/setAttr';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import className from '@sparkle/js/helpers/utils/className';
import scroll from '@sparkle/js/methods/browser/scroll';
import attrOption from '@sparkle/js/utilities/.internal/options/attrOption';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';

/*
 * WorkFilter functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const WorkFilter = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: WorkFilter.SELECTOR,

        // Settings
        autofilter: WorkFilter.AUTOFILTER
    });

    // Settings
    options = attrOption(options, el, ['autofilter'], options.selector);

    // Modifiers
    that.modifiers = {
        vWeddings: 'v--weddings',
        vCouples: 'v--couples',
        vEvents: 'v--events',
        vUnderline: 'v--underline',
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        triggers: () => selectAll(`${options.selector}__trigger`, el),
        thumbnails: () => selectAll(`${options.selector}__thumbnail`),
        links: () => selectAll(`${options.selector}__link`),
        selected: () => select(`${options.selector}__selected`),
        hidden: () => select(`${options.selector}__hidden`)
    });

    // Init
    that.init();
};

/**
 * Init
 */
WorkFilter.prototype.init = function () {
    const that = this;

    // Fct - Filter -> Apply
    const filter = async filter => {

        // Update navigation
        each(that.dom.$('triggers'), el => {
            if (filter === attrOption({}, el, ['filter'], that.options.selector).filter) {
                addClass(el, that.modifiers.vUnderline);
            } else {
                removeClass(el, that.modifiers.vUnderline);
            }
        });

        // Update links
        each(that.dom.$('links'), el => {
            const uri = new URL(getAttr(el, 'href'));
            uri.searchParams.set('autofilter', filter);
            setAttr(el, 'href', uri.href);
        });

        // All
        if (filter === 'all') {
            each(that.dom.$('thumbnails'), el => {
                that.dom.$('selected').appendChild(el);
            });

        // Weddings
        } else if (filter === 'weddings') {
            each(that.dom.$('thumbnails'), el => {
                if (hasClass(el, that.modifiers.vWeddings)) {
                    that.dom.$('selected').appendChild(el);
                } else {
                    that.dom.$('hidden').appendChild(el);
                }
            });

        // Couples
        } else if (filter === 'couples') {
            each(that.dom.$('thumbnails'), el => {
                if (hasClass(el, that.modifiers.vCouples)) {
                    that.dom.$('selected').appendChild(el);
                } else {
                    that.dom.$('hidden').appendChild(el);
                }
            });

        // Events
        } else if (filter === 'events') {
            each(that.dom.$('thumbnails'), el => {
                if (hasClass(el, that.modifiers.vEvents)) {
                    that.dom.$('selected').appendChild(el);
                } else {
                    that.dom.$('hidden').appendChild(el);
                }
            });
        }

        // Force all scroll triggers to be refreshed
        ScrollVibe.refresh();

        return;
    };

    // Listener - Trigger
    that.events.on(that.dom.$('triggers'), 'click', async e => {
        e.preventDefault();

        // Apply filters
        await filter(attrOption({}, e.target, ['filter'], that.options.selector).filter);

        // Scroll
        scroll(that.el, { speed: 1000, ease: 'easeInQuad' });
    });

    // Autofilter
    if (that.options.autofilter !== 'all') {
        filter(that.options.autofilter);
    }
};

/**
 * Destroy
 */
WorkFilter.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
WorkFilter.SELECTOR = '.js-workFilter';
WorkFilter.AUTOFILTER = 'all';

export default WorkFilter;
