import each from '../helpers/collection/each';
import isObject from '../helpers/lang/isObject';
import has from '../helpers/object/has';

/*
 * Easily manage a large number of selectors by efficiently delaying the selection
 * whenever it is really required. DOM selection is resource extensive, it needs to
 * be avoided as much as possible
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @constructor
 */
const DomManager = function () {
    this.props = {};
    this.nodes = {};
};

/**
 * Set a property as a function. Several properties can be specified
 *
 * @param {string|Object} property
 * @param {function} fct
 */
DomManager.prototype.set = function (property, fct) {

    // Multiple properties specified
    if (isObject(property)) {
        each(property, (fct, prop) => {
            this.set(prop, fct);
        });

    // Single property
    } else if (!has(this.props, property)) {
        this.props[property] = fct;
    }
};

/**
 * Unset a property
 *
 * @param {string} property
 */
DomManager.prototype.unset = function (property) {
    delete this.props[property];
    delete this.nodes[property];
};

/**
 * Return the value of the specified property
 *
 * @param {string} property
 *
 * @returns {Node|Array|null}
 */
DomManager.prototype.$ = function (property) {
    if (!has(this.nodes, property)) {
        try {
            this.nodes[property] = this.props[property]();
        } catch (err) {
            this.nodes[property] = null;
            if (DomManager.DEBUG) {
                console.log(err);
            }
        }
    }
    return this.nodes[property];
};

/**
 * Destroy all registered events
 *
 * @returns {this}
 */
DomManager.prototype.destroy = function () {
    this.props = {};
    this.nodes = {};
};

/**
 * Constants
 */
DomManager.DEBUG = false;

export default DomManager;
