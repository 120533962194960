import FontFaceObserver from 'fontfaceobserver';
import imagesLoaded from 'imagesloaded';
import addClass from '@sparkle/js/fn/attributes/addClass';
import hasClass from '@sparkle/js/fn/attributes/hasClass';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import select from '@sparkle/js/fn/select/select';
import setProps from '@sparkle/js/helpers/object/setProps';
import hide from '@sparkle/js/methods/animate/hide';
import show from '@sparkle/js/methods/animate/show';
import scroll from '@sparkle/js/methods/browser/scroll';
import attrOption from '@sparkle/js/utilities/.internal/options/attrOption';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * WorkProjectHero functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const WorkProjectHero = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: WorkProjectHero.SELECTOR,

        // Settings
        imageAlign: WorkProjectHero.IMAGE_ALIGN
    });

    // Settings
    options = attrOption(options, el, ['imageAlign'], options.selector);

    // Modifiers
    that.modifiers = {
        vAutostart: 'v--autostart',
        vInit: 'v--init',
        vFixed: 'v--fixed',
        vApp: 'v--app'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        image: () => select(`${options.selector}__image`, el),
        imagePicture: () => select(`${options.selector}__image-picture`, that.dom.$('image')),
        title: () => select(`${options.selector}__title`, el),
        subtitle: () => select(`${options.selector}__subtitle`, el),
        symbol: () => select(`${options.selector}__symbol`, el),
        loader: () => select(`${options.selector}__loader`, el),
        overlay: () => select(`${options.selector}__overlay`, el),
        close: () => select(`${options.selector}__close`, el),
        introduction: () => select(`${options.selector}__introduction`)
    });

    // Init
    that.init();
};

/**
 * Init
 */
WorkProjectHero.prototype.init = function () {
    const that = this;

    // Global variable
    that.isAnimating = false;
    that.nextAction = '';
    that.state = '';

    // Listener - Show
    that.events.on(that.el, 'handleShow', () => {
        that.show();
    });

    // Welcome not active -> Show
    if (hasClass(that.el, that.modifiers.vAutostart)) {
        that.events.trigger(that.el, 'handleShow');
    }

    // Listener - Pin
    that.events.on(that.el, 'handlePin', () => {
        if (!hasClass(that.el, that.modifiers.vApp)) {

            // Pin overlay
            addClass(that.dom.$('overlay'), that.modifiers.vFixed);

            // Actions ?
            if (that.isAnimating) {
                if (that.state !== 'pin') {
                    that.nextAction = 'pin';
                } else {
                    that.nextAction = '';
                }
            } else {
                that.nextAction = '';
                that.pin();
            }
        }
    });

    // Listener - Unpin
    that.events.on(that.el, 'handleUnpin', () => {
        if (!hasClass(that.el, that.modifiers.vApp)) {

            // Unpin overlay
            removeClass(that.dom.$('overlay'), that.modifiers.vFixed);

            // Actions ?
            if (that.isAnimating) {
                if (that.state !== 'unpin') {
                    that.nextAction = 'unpin';
                } else {
                    that.nextAction = '';
                }
            } else {
                that.nextAction = '';
                that.unpin();
            }
        }
    });

    // Listener - Scroll
    that.events.on(that.dom.$('symbol'), 'click', e => {
        e.preventDefault();
        scroll(that.dom.$('introduction'), { speed: 1000, ease: 'easeInQuad' });
    });
};

/**
 * Show
 */
WorkProjectHero.prototype.show = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        that.isAnimating = true;
        Tweener.set([that.dom.$('title'), that.dom.$('subtitle')], { opacity: 0 });
        return;
    };

    // Fct - Loader -> Hide
    const loaderHide = async () => {
        await new Tweener({ speed: 300, ease: 'power3.out' })
            .add(that.dom.$('loader'), {
                scale: { to: 0 },
                opacity: { to: 1, ease: 'power2.in' }
            })
            .play();

        hide(that.dom.$('loader'));
        return;
    };

    // Fct - Loaded Fonts
    const loadedFonts = async () => {

        // Font Observers
        const observers = [];
        observers.push(new FontFaceObserver('graphik').load(null, 5000));
        observers.push(new FontFaceObserver('cardo').load(null, 5000));

        // Fonts Loaded
        await Promise.all(observers);

        return;
    };

    // Fct - Loaded Image
    const loadedImg = async () => {
        await new Promise(resolve => {
            imagesLoaded(that.dom.$('imagePicture'), () => {
                resolve();
            });
        });
        return;
    };

    // Fct - Image -> Show
    const imageShow = async () => {
        await new Promise(resolve => {
            that.events.on(that.dom.$('imagePicture'), 'onReveal', () => {
                resolve();
            });
            that.events.trigger(that.dom.$('imagePicture'), 'handleReveal');
        });
        return;
    };

    // Fct - Title -> Show
    const titleShow = async () => {

        removeClass(that.el, that.modifiers.vInit);

        await new Tweener({ speed: 250, delay: 250, ease: 'power3.out' })
            .add(that.dom.$('title'), {
                x: { from: 30, to: 0 },
                opacity: { to: 1, ease: 'power1.out' }
            })
            .add(that.dom.$('subtitle'), {
                x: { from: -30, to: 0, delay: 200 },
                opacity: { to: 1, ease: 'power1.out', delay: 200 },
            })
            .play();

        return;
    };

    // Fct - CTA -> Show
    const symbolShow = async () => {
        if (!hasClass(that.dom.$('overlay'), that.modifiers.vFixed)) {
            await new Tweener({ speed: 250, ease: 'power3.out' })
                .add(that.dom.$('symbol'), {
                    scale: { from: 0, to: 1, delay: 250 }
                })
                .onStart(() => {
                    show(that.dom.$('symbol'));
                })
                .play();
        }
        return;
    };

    // Fct - Done
    const done = async () => {
        that.isAnimating = false;
        if (that.nextAction === 'unpin') {
            that.unpin();
        } else if (that.nextAction === 'pin') {
            that.pin();
        }
        return;
    };

    // Init & proceed
    await init();
    await Promise.all([loadedFonts(), loadedImg()]);
    await loaderHide();
    await imageShow();
    await Promise.all([titleShow(), symbolShow()]);
    done();
};

/**
 * Pin
 */
WorkProjectHero.prototype.pin = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        if (that.state !== 'pin') {
            that.isAnimating = true;
            that.state = 'pin';
            return;
        }
    };

    // Fct - Symbol -> Hide
    const symbolHide = async () => {
        await new Tweener({ speed: 250, ease: 'power3.out' })
            .add(that.dom.$('symbol'), {
                scale: { to: 0 }
            })
            .play();

        hide(that.dom.$('symbol'));

        await new Tweener({ speed: 250, ease: 'power3.out' })
            .add(that.dom.$('close'), {
                scale: { from: 0, to: 1 }
            })
            .onStart(() => {
                show(that.dom.$('close'));
            })
            .play();

        return;
    };

    // Fct - Done
    const done = async () => {
        that.isAnimating = false;
        if (that.nextAction === 'unpin') {
            that.unpin();
        }
        return;
    };

    // Init & proceed
    await init();
    await symbolHide();
    done();
};

/**
 * Unpin
 */
WorkProjectHero.prototype.unpin = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        if (that.state !== 'unpin') {
            that.isAnimating = true;
            that.state = 'unpin';
        }
        return;
    };

    // Fct - Symbol -> Show
    const symbolShow = async () => {
        await new Tweener({ speed: 250, ease: 'power3.out' })
            .add(that.dom.$('close'), {
                scale: { to: 0 }
            })
            .play();
        hide(that.dom.$('close'));
        await new Tweener({ speed: 250, ease: 'power3.out' })
            .add(that.dom.$('symbol'), {
                scale: { from: 0, to: 1 }
            })
            .onStart(() => {
                show(that.dom.$('symbol'));
            })
            .play();
        return;
    };

    // Fct - Done
    const done = async () => {
        that.isAnimating = false;
        if (that.nextAction === 'pin') {
            that.pin();
        }
        return;
    };

    // Init & proceed
    await init();
    await symbolShow();
    done();
};

/**
 * Destroy
 */
WorkProjectHero.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
WorkProjectHero.SELECTOR = '.js-workProjectHero';
WorkProjectHero.IMAGE_ALIGN = 'v--center';

export default WorkProjectHero;
