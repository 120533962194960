import FontFaceObserver from 'fontfaceobserver';
import hasClass from '@sparkle/js/fn/attributes/hasClass';
import hide from '@sparkle/js/fn/attributes/hide';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import show from '@sparkle/js/fn/attributes/show';
import scrollPosition from '@sparkle/js/fn/browser/scrollPosition';
import html from '@sparkle/js/fn/manipulation/html';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import setProps from '@sparkle/js/helpers/object/setProps';
import className from '@sparkle/js/helpers/utils/className';
import scroll from '@sparkle/js/methods/browser/scroll';
import sleep from '@sparkle/js/methods/utils/sleep';
import attrOption from '@sparkle/js/utilities/.internal/options/attrOption';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Hero functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Hero = function (el, options) {
    const that = this;

    // Options - Selector
    setProps(options, {
        selector: Hero.SELECTOR,

        // Options
        subtitle: Hero.SUBTITLE,
        scroll: Hero.SCROLL,
        layout: Hero.LAYOUT
    });

    // Set Options from Attr
    options = attrOption(options, el, ['subtitle', 'scroll', 'layout'], options.selector);

    // Selectors
    const selectors = {

        // Statuses
        vInit: '.v--init',
        vAutostart: '.v--autostart',
        vReady: '.v--ready',

        // Elements
        title: `${options.selector}__title`,
        subtitle: `${options.selector}__subtitle`,
        introduction: `${options.selector}__introduction`,
        main: `${options.selector}__main`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Hero.prototype.init = function () {
    const that = this;

    // Classes
    const classAutostart = className(that.selectors.vAutostart);

    // Listener - Show
    that.events.on(that.el, 'handleShow', () => {
        that.show();
    });

    // Welcome not active -> Show
    if (hasClass(that.el, classAutostart)) {
        that.events.trigger(that.el, 'handleShow');
    }
};

/**
 * Show
 */
Hero.prototype.show = function () {
    const that = this;

    // Mini
    if (that.options.layout === 'mini') {
        that.showMini();

    // Title
    } else if (that.options.layout === 'title') {
        that.showTitle();
    }
};

/**
 * Show - Normal
 */
Hero.prototype.showTitle = async function () {
    const that = this;

    // Elements
    const elTitle = select(that.selectors.title, that.el);
    const elSubtitle = select(that.selectors.subtitle, elTitle);
    const elIntroduction = select(that.selectors.introduction, that.el);
    const elMain = select(that.selectors.main);

    // Classes
    const classInit = className(that.selectors.vInit);

    // Fct - Init
    const init = async () => {
        Tweener.set([elTitle, elIntroduction], { opacity: 0 });
        if (elSubtitle && that.options.subtitle !== '') {
            that.options.title = html(elSubtitle);
            html(elSubtitle, that.options.subtitle);
        }
        removeClass(that.el, classInit);
        return;
    };

    // Fct - Loaded Fonts
    const loadedFonts = async () => {

        // Font Observers
        const observers = [];
        observers.push(new FontFaceObserver('graphik').load(null, 5000));
        observers.push(new FontFaceObserver('cardo').load(null, 5000));

        // Fonts Loaded
        await Promise.all(observers);

        return;
    };

    // Fct - Loaded
    const loaded = async () => {
        await loadedFonts();
        ScrollVibe.refresh();
        return;
    };

    // Fct - Title -> Show
    const titleShow = async () => {
        await new Tweener({ speed: 600, delay: 25, ease: 'power2.inOut' })
            .add(elSubtitle, {
                y: { from: '-100%', to: 0 }
            })
            .onStart(() => {
                Tweener.set(elTitle, { opacity: 1 });
            })
            .play();

        return;
    };

    // Fct - Introduction -> Show
    const introductionShow = async () => {
        await new Tweener({ speed: 600, ease: 'power3.out' })
            .add(elIntroduction, {
                x: { from: 40, to: 0 },
                opacity: { to: 1, ease: 'power1.out' }
            })
            .play();

        return;
    };

    // Fct - Title -> Animate
    const titleAnimate = async () => {
        await new Tweener({ speed: 600, ease: 'power2.in' })
            .add(elSubtitle, {
                y: { to: '100%' }
            })
            .play();

        html(elSubtitle, that.options.title);
        Tweener.set(elSubtitle, { y: 0 });

        await new Tweener({ speed: 750, ease: 'power2.out' })
            .add(elSubtitle, {
                x: { from: '-100%', to: 0 }
            })
            .play();

        return;
    };

    // Fct - Title -> Swap
    const titleSwap = async () => {
        if (elSubtitle && that.options.subtitle !== '') {
            await titleAnimate();
        }
        return;
    };

    // Fct - Main -> Show
    const mainShow = async () => {
        await new Tweener({ speed: 500, ease: 'power3.out' })
            .add(elMain, {
                y: { from: 75, to: 0 },
                opacity: { to: 1, ease: 'power2.out' }
            })
            .play();

        Tweener.clear(elMain, 'y');

        return;
    };

    // Fct - Done
    const done = async () => {
        return;
    };

    // Init & proceed
    await init();
    await loaded();
    await titleShow();
    await introductionShow();
    await Promise.all([mainShow(), titleSwap()]);
    done();
};

/**
 * Show - Mini
 */
Hero.prototype.showMini = async function () {
    const that = this;

    // Elements
    const elIntroduction = select(that.selectors.introduction, that.el);
    const elScrollTo = select(that.options.scroll);
    const elMain = select(that.selectors.main);

    // Classes
    const classInit = className(that.selectors.vInit);

    // Fct - Init
    const init = async () => {
        Tweener.set(elIntroduction, { opacity: 0 });
        removeClass(that.el, classInit);
        return;
    };

    // Fct - Loaded Fonts
    const loadedFonts = async () => {

        // Font Observers
        const observers = [];
        observers.push(new FontFaceObserver('graphik').load(null, 5000));

        // Fonts Loaded
        await Promise.all(observers);

        return;
    };

    // Fct - Loaded
    const loaded = async () => {
        await loadedFonts();
        return;
    };

    // Fct - Introduction -> Show
    const introductionShow = async () => {
        await new Promise(resolve => {
            that.events.once(elIntroduction, 'onReveal', () => {
                resolve();
            });
            that.events.trigger(elIntroduction, 'handleReveal');
        });
        return;
    };

    // Fct - Scroll -> To #
    const scrollTo = async () => {
        if (elScrollTo && scrollPosition().y === 0) {
            await scroll(elScrollTo, { speed: 1000, ease: 'easeInQuad' });
        }
        return;
    };

    // Fct - Main -> Show
    const mainShow = async () => {
        await new Tweener({ speed: 500, ease: 'power3.out' })
            .add(elMain, {
                y: { from: 75, to: 0 },
                opacity: { to: 1, ease: 'power2.out' }
            })
            .play();

        Tweener.clear(elMain, 'y');
        return;
    };

    // Fct - Done
    const done = async () => {
        return;
    };

    // Init & proceed
    await init();
    await loaded();
    await introductionShow();
    await mainShow();
    await scrollTo();
    done();
};

/**
 * Destroy
 */
Hero.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
Hero.SELECTOR = '.js-hero';
Hero.SUBTITLE = '';
Hero.SCROLL = '';
Hero.LAYOUT = 'normal';

export default Hero;
