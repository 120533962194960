import select from '../select/select';

/*
 * Is Node Visible ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 *
 * @returns {boolean}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // Return true if visible
    return (window.getComputedStyle ? getComputedStyle(node, null) : node.currentStyle).display !== 'none';
}
