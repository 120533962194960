import attrOption from './.internal/options/attrOption';
import getAttrOption from './.internal/options/getAttrOption';
import DomManager from './DomManager';
import EventsManager from './EventsManager';
import Tweener from './Tweener';
import addClass from '../fn/attributes/addClass';
import hide from '../fn/attributes/hide';
import isVisible from '../fn/attributes/isVisible';
import removeClass from '../fn/attributes/removeClass';
import show from '../fn/attributes/show';
import scrollPosition from '../fn/browser/scrollPosition';
import windowDimensions from '../fn/browser/windowDimensions';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import outerHeight from '../fn/style/outerHeight';
import outerWidth from '../fn/style/outerWidth';
import each from '../helpers/collection/each';
import isNull from '../helpers/lang/isNull';
import get from '../helpers/object/get';
import setProps from '../helpers/object/setProps';

/*
 * Mousetrack follows the mouse position and appears when requested
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Mousetrack = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: Mousetrack.SELECTOR,

        // Settings
        speed: Mousetrack.SPEED,
        speedShadow: Mousetrack.SPEED_SHADOW
    });

    // Settings
    options = attrOption(options, el, ['speed', 'speedShadow'], options.selector);

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        track: () => select(`${options.selector}__track`, el),
        trackSize: () => select(`${options.selector}__track-size`, that.dom.$('track')),
        trackBg: () => select(`${options.selector}__track-bg`, that.dom.$('track')),
        trackIcon: () => select(`${options.selector}__track-icon`, that.dom.$('track')),
        triggers: () => selectAll(`${options.selector}__trigger`),
        shadow: () => select(`${options.selector}__shadow`),
        shadowBg: () => select(`${options.selector}__shadow-bg`, that.dom.$('shadow'))
    });

    // Init
    that.init();
};

/**
 * Init
 */
Mousetrack.prototype.init = function () {
    const that = this;

    // Global variables
    that.tween = null;
    that.classes = '';

    // Listener - Device Touchable
    that.events.on(document, 'onTouchable', () => {
        that.onTouchable();
    });

    // Listener - Device Not Touchable
    that.events.on(document, 'onNoTouchable', () => {
        that.onNoTouchable();
    });
};

/**
 * Device - No Touchable
 */
Mousetrack.prototype.onNoTouchable = function () {
    const that = this;

    // Variables
    let isEnter = false;
    let isShow = false;
    let enterClasses = '';

    // Fct - Mouse Enter
    const onMouseEnter = (pageX, pageY, classes) => {
        isEnter = true;
        enterClasses = classes;
        if (!isShow) {
            that.on(pageX, pageY, enterClasses);
        }
    };

    // Fct - Mouse Leave
    const onMouseLeave = () => {
        isEnter = false;
        enterClasses = '';
        if (!isShow) {
            that.off();
        }
    };

    // Fct - Show
    const onShow = (pageX, pageY, classes) => {
        isShow = true;
        that.on(pageX, pageY, classes);
    };

    // Fct - Hide
    const onHide = (pageX, pageY) => {
        isShow = false;
        if (isEnter) {
            that.on(pageX, pageY, enterClasses);
        } else {
            that.off();
        }
    };

    // Listeners - Triggers
    each(that.dom.$('triggers'), el => {

        // Remove cursor for trigger
        el.style.cursor = 'none';

        that.events.on(el, 'mouseenter', e => {
            onMouseEnter(e.pageX, e.pageY, getAttrOption(el, 'class', that.options.selector, ''));
        });

        that.events.on(el, 'mouseleave', e => {
            onMouseLeave();
        });
    });

    // Listener - Show
    that.events.on(that.dom.$('track'), 'handleShow', e => {
        onShow(e.detail.pageX, e.detail.pageY, e.detail.classes);
    });

    // Listener - Hide
    that.events.on(that.dom.$('track'), 'handleHide', e => {
        onHide(get(e.detail, 'pageX', null), get(e.detail, 'pageY', null));
    });
};

/**
 * Device - Touchable
 */
Mousetrack.prototype.onTouchable = function () {
    const that = this;

    // Remove all listeners
    each(that.dom.$('triggers'), el => {
        that.events.off(el, ['mouseenter', 'mouseleave']);
    });
    that.events.off(that.dom.$('track'), ['handleShow', 'handleHide']);

    // Ensure it is off
    that.off();
};

/**
 * On
 *
 * @param {number|null} pageX
 * @param {number|null} pageY
 * @param {string} classes
 */
Mousetrack.prototype.on = async function (pageX, pageY, classes) {
    const that = this;

    // Should tween on show ?
    let shouldTween = true;

    // Fct - Init
    const init = async () => {

        // Is hidden
        if (!isVisible(that.dom.$('track'))) {
            Tweener.set(that.dom.$('track'), { opacity: 0 });
            Tweener.set([that.dom.$('trackBg'), that.dom.$('trackIcon')], { scale: 0 });
            if (that.dom.$('shadow')) {
                Tweener.set(that.dom.$('shadow'), { opacity: 0 });
                Tweener.set(that.dom.$('shadowBg'), { scale: 0 });
            }
            show([that.dom.$('track'), that.dom.$('shadow')]);
            shouldTween = false;
        }

        // Remove the previously added classes & add the new ones
        if (that.classes !== '') {
            removeClass([that.dom.$('track'), that.dom.$('shadow')], that.classes);
        }
        if (classes !== '') {
            that.classes = classes;
            addClass([that.dom.$('track'), that.dom.$('shadow')], that.classes);
        }

        // Cursor
        document.body.style.cursor = 'none';

        return;
    };

    // Fct - Track -> Show
    const trackShow = async () => {

        // Kill tween
        if (that.tween) {
            that.tween.kill();
        }

        // Create tween
        that.tween = new Tweener({ speed: 250, ease: 'power1.inOut' })
            .add(that.dom.$('trackBg'), {
                scale: { to: 1 }
            })
            .add(that.dom.$('trackIcon'), {
                scale: { to: 1, delay: 50 }
            })
            .onStart(() => {
                Tweener.set([that.dom.$('track'), that.dom.$('trackBg'), that.dom.$('trackIcon')], { opacity: 1 });
                if (that.dom.$('shadow')) {
                    Tweener.set([that.dom.$('shadow'), that.dom.$('shadowBg')], { opacity: 1 });
                }
            });
        if (that.dom.$('shadow')) {
            that.tween.add(that.dom.$('shadowBg'), {
                scale: { to: 1 }
            });
        }
        await that.tween.play();

        return;
    };

    // Fct - Update position
    const updatePosition = (x, y, tween = false) => {

        // Sizes & positions
        const scroll = scrollPosition();
        const windowSize = windowDimensions();
        const size = {
            width: outerWidth(that.dom.$('trackSize')),
            height: outerHeight(that.dom.$('trackSize'))
        };

        // Mouse position relative to the viewport
        const toX = Math.round(Math.min(Math.max(x - scroll.x - size.width / 2, 0), windowSize.width - size.width));
        const toY = Math.round(Math.min(Math.max(y - scroll.y - size.height / 2, 0), windowSize.height - size.height));

        // Tween to position
        if (tween && that.options.speed > 0) {
            const tweener = new Tweener({ speed: that.options.speed, ease: 'power2.out' })
                .add(that.dom.$('track'), {
                    x: { to: toX },
                    y: { to: toY },
                });
            if (that.dom.$('shadow')) {
                tweener.add(that.dom.$('shadow'), {
                    x: { to: toX, speed: that.options.speedShadow },
                    y: { to: toY, speed: that.options.speedShadow },
                });
            }
            tweener.play();

        // Position
        } else {
            Tweener.set(that.dom.$('track'), { x: toX, y: toY });
            if (that.dom.$('shadow')) {
                Tweener.set(that.dom.$('shadow'), { x: toX, y: toY });
            }

        }
    };

    // Init & proceed
    await init();

    // Update to the entered position
    if (!isNull(pageX) && !isNull(pageY)) {
        updatePosition(pageX, pageY, shouldTween);
    }

    // Show
    trackShow();

    // Listener - Mouse move
    that.events.on(document, 'mousemove', e => {
        updatePosition(e.pageX, e.pageY, true);
    });
};

/**
 * Off
 */
Mousetrack.prototype.off = async function () {
    const that = this;

    // Fct - Done
    const done = async () => {
        hide([that.dom.$('track'), that.dom.$('shadow')]);
        document.body.style.cursor = 'auto';
        that.events.off(document, 'mousemove');
        return;
    };

    // Fct - Track -> Hide
    const trackHide = async () => {

        // Kill tween
        if (that.tween) {
            that.tween.kill();
        }

        that.tween = new Tweener({ speed: 250, ease: 'power1.inOut' })
            .add(that.dom.$('trackBg'), {
                scale: { to: 0 }
            })
            .add(that.dom.$('trackIcon'), {
                scale: { to: 0 }
            });

        if (that.dom.$('shadow')) {
            that.tween.add(that.dom.$('shadowBg'), {
                scale: { to: 0 }
            });
        }
        await that.tween.play();

        return;
    };

    // Proceed
    await trackHide();
    done();
};

/**
 * Destroy
 */
Mousetrack.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
Mousetrack.SELECTOR = '.js-mousetrack';
Mousetrack.SPEED = 350;
Mousetrack.SPEED_SHADOW = 500;

export default Mousetrack;
