import onCallback from '../.internal/callback/onCallback';

/*
 * Sleep for a while. Timeout must be specified in milliseconds (ms).
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {number} timeout
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default async function (timeout, options) {
    const that = this;

    await new Promise(resolve => {
        setTimeout(() => {
            onCallback.call(that, 'onComplete', options);
            resolve();
        }, timeout);
    });

    return;
}
