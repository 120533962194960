import '../.defaults/animate';
import fadeIn from '../.internal/animate/fadeIn';
import fadeOut from '../.internal/animate/fadeOut';
import slideDown from '../.internal/animate/slideDown';
import slideUp from '../.internal/animate/slideUp';
import onCallback from '../.internal/callback/onCallback';
import hide from '../../fn/attributes/hide';
import isVisible from '../../fn/attributes/isVisible';
import show from '../../fn/attributes/show';
import data from '../../fn/data/data';
import selectAll from '../../fn/select/selectAll';
import each from '../../helpers/collection/each';
import isUndefined from '../../helpers/lang/isUndefined';
import or from '../../helpers/lang/or';
import get from '../../helpers/object/get';
import merge from '../../helpers/object/merge';
import Sparkle from '../../Sparkle';

/*
 * Animate 1 or more Nodes based on the specified transition. Similar to the jQuery
 * animate functions. Possible transitions are: slideIn, slideOut, fadeIn, fadeOut,
 * show, hide. Animation functions must return a Promise. animate() must be called
 * to animate a node. Do not call individual animations directly.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string|Array} nodes
 * @param {string} transition
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default async function (nodes, transition, options) {
    const that = this;

    options = options || {};

    // This function MUST return a Promise. fadeIn(), fadeOut(), slideDown(),
    // slideUp() all return a Promise !
    const animateNode = function (node) {

        // Data - Animate
        const nodeDataAnimate = data(node, 'data-sparkle-animate') || {};

        // Data - Animating
        const animating = get(nodeDataAnimate, 'animating', false);
        const animation = animating ? get(nodeDataAnimate, 'animation', '') : '';

        // Action to perform
        let action = '';

        // Progress
        let progress = 0;

        // Transition different from current animation
        if (transition !== animation) {

            // SlideUp requested
            if (transition === 'slideUp') {
                action = or(animation, 'fadeIn', 'fadeOut') ? 'fadeOut' : '';

            // SlideDown requested
            } else if (transition === 'slideDown') {
                action = or(animation, 'fadeIn', 'fadeOut') ? 'fadeIn' : '';

            // FadeOut requested
            } else if (transition === 'fadeOut') {
                action = or(animation, 'slideUp', 'slideDown') ? 'slideUp' : '';

            // FadeIn requested
            } else if (transition === 'fadeIn') {
                action = or(animation, 'slideUp', 'slideDown') ? 'slideDown' : '';

            // Show
            } else if (transition === 'show') {
                if (or(animation, 'slideDown', 'slideUp')) {
                    action = 'slideDown';
                } else if (or(animation, 'fadeIn', 'fadeOut')) {
                    action = 'fadeIn';
                }
                progress = 1;

            // Hide
            } else if (transition === 'hide') {
                if (or(animation, 'slideDown', 'slideUp')) {
                    action = 'slideUp';
                } else if (or(animation, 'fadeIn', 'fadeOut')) {
                    action = 'fadeOut';
                }
                progress = 1;
            }
        }

        // Specify action if not already specified
        action = action || transition;

        // Perform Show/Hide or Animate not required
        if (or(action, 'show', 'hide') || (animation === '' && ((isVisible(node) && or(action, 'slideDown', 'fadeIn')) || (!isVisible(node) && or(action, 'slideUp', 'fadeOut'))))) {
            return new Promise(resolve => {

                // Show or Hide
                if (action === 'show') {
                    show(node);
                } else if (action === 'hide') {
                    hide(node);
                }

                // On Complete
                if (options.onComplete) {
                    options.onComplete.apply(this, arguments);
                }

                // Resolve Promise
                resolve();
            });
        }

        // Perform Fade / Slide
        let animateFunction;

        // SlideUp
        if (action === 'slideUp') {
            animateFunction = slideUp;

        // SlideDown
        } else if (action === 'slideDown') {
            animateFunction = slideDown;

        // FadeIn
        } else if (action === 'fadeIn') {
            animateFunction = fadeIn;

        // FadeOut
        } else if (action === 'fadeOut') {
            animateFunction = fadeOut;
        }

        // A function is defined, call the selected Animation
        if (!isUndefined(animateFunction)) {

            // Options
            options = merge(Sparkle.methods.defaults.animate, options);

            // Return the animate function which MUST return a Promise
            return animateFunction(node, nodeDataAnimate, animating, animation, options.speed / 1000, options.ease, progress, {

                // On Start
                onStart: () => {
                    if (options.onStart) {
                        options.onStart.apply(this, arguments);
                    }
                },

                // On Update
                onUpdate: () => {
                    if (options.onUpdate) {
                        options.onUpdate.apply(this, arguments);
                    }
                },

                // On Complete
                onComplete: () => {
                    if (options.onComplete) {
                        options.onComplete.apply(this, arguments);
                    }
                }

            });
        }

        // No function is defined, return a Promise
        return new Promise(resolve => {
            resolve();
        });

    };

    // Create promises
    const promises = [];

    // Add chosen nodes to Promises
    each(selectAll(nodes), node => {
        promises.push(animateNode(node));
    });

    // Once all Promises are resolved, perform a onCallback
    await Promise.all(promises);

    // onComplete
    onCallback.call(that, 'onComplete', options);

    return;
}
