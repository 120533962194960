import addClass from '@sparkle/js/fn/attributes/addClass';
import hide from '@sparkle/js/fn/attributes/hide';
import removeClass from '@sparkle/js/fn/attributes/removeClass';
import show from '@sparkle/js/fn/attributes/show';
import select from '@sparkle/js/fn/select/select';
import setProps from '@sparkle/js/helpers/object/setProps';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * Sideburger functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const Sideburger = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: Sideburger.SELECTOR
    });

    // Modifiers
    that.modifiers = {
        vHover: 'v--hover'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        hamburger: () => select(`${options.selector}__hamburger`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
Sideburger.prototype.init = function () {
    const that = this;

    // Status
    that.isMouseOver = false;

    // Listener - Device Touchable
    that.events.on(document, 'onTouchable', () => {
        that.onTouchable();
    });

    // Listener - Device Not Touchable
    that.events.on(document, 'onNoTouchable', () => {
        that.onNoTouchable();
    });

    // Listener - Handle Show
    that.events.on(that.el, 'handleShow', () => {
        that.show();
    });

    // Listener - Handle Hide
    that.events.on(that.el, 'handleHide', () => {
        that.hide();
    });
};

/**
 * Show
 */
Sideburger.prototype.show = async function () {
    const that = this;

    await new Tweener({ speed: 400, ease: 'power2.inOut' })
        .add(that.el, {
            opacity: { from: 0, to: 1 }
        })
        .onStart(() => {
            show(that.el);
        })
        .play();

    that.events.trigger(that.el, 'onShow');
};

/**
 * Hide
 */
Sideburger.prototype.hide = function () {
    const that = this;

    // Hide
    hide(that.el);

    // Dispatch event
    that.events.trigger(that.el, 'onHide');
};

/**
 * Device - No Touchable
 */
Sideburger.prototype.onNoTouchable = function () {
    const that = this;

    // Listener - Over
    that.events.on(that.el, 'mouseover', e => {
        that.isMouseOver = true;
        addClass(that.dom.$('hamburger'), that.modifiers.vHover);
    });

    // Listener - Out
    that.events.on(that.el, 'mouseout', e => {
        that.isMouseOver = false;
        removeClass(that.dom.$('hamburger'), that.modifiers.vHover);
    });
};

/**
 * Device - Touchable
 */
Sideburger.prototype.onTouchable = function () {
    const that = this;

    // Remove Class
    removeClass(that.dom.$('hamburger'), that.modifiers.vHover);

    // Remove listeners
    that.events.off(that.el, ['mouseover', 'mouseout']);
};

/**
 * Destroy
 */
Sideburger.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
};

/**
 * Constants
 */
Sideburger.SELECTOR = '.js-sideburger';

export default Sideburger;
