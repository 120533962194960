import data from '../fn/data/data';
import selectAll from '../fn/select/selectAll';
import inArray from '../helpers/array/inArray';
import each from '../helpers/collection/each';
import isNull from '../helpers/lang/isNull';

/*
 * Enable to register plugins and clean up plugins in a comprehensive way
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */
const Plugins = function () {};

/**
 * Register an instance to a specific node
 *
 * @param {Node} node
 * @param {string} selector
 * @param {Object} instance
 */
Plugins.register = function (node, selector, instance) {
    data(node, selector, instance);
    Plugins.addSelector(selector);
};

/**
 * Return true if the plugin can be registered, false otherwise
 *
 * @param {Node} node
 * @param {string} selector
 *
 * @param {boolean}
 */
Plugins.canRegister = function (node, selector) {
    return isNull(data(node, selector));
};

/**
 * Return all currently registered selectors
 *
 * @returns {Array}
 */
Plugins.getSelectors = function () {
    return data(document, Plugins.DATA_NAME) || [];
};

/**
 * Add a selector to the global list if its does not already exist
 *
 * @param {string} selector
 */
Plugins.addSelector = function (selector) {
    const registered = Plugins.getSelectors();
    if (!inArray(registered, selector)) {
        registered.push(selector);
        data(document, Plugins.DATA_NAME, registered);
    }
};

/**
 * Destroy all instances within the specified context
 *
 * @param {Node|string} context
 */
Plugins.clear = function (context) {
    each(Plugins.getSelectors(), selector => {
        each(selectAll(selector, context), node => {
            let instance = data(node, selector);
            if (instance) {
                try {
                    instance.destroy();
                } catch (err) {
                    if (Plugins.DEBUG) {
                        console.log(err);
                    }
                }
                data(node, selector, null);
                instance = null;
            }
        });
    });
};

/**
 * Constants
 */
Plugins.DATA_NAME = 'sparkle-plugins';
Plugins.DEBUG = false;

export default Plugins;
