import getAttrStyle from './.internal/getAttrStyle';
import hide from '../../../fn/attributes/hide';
import data from '../../../fn/data/data';
import get from '../../../helpers/object/get';
import Tweener from '../../../utilities/Tweener';

/*
 * Fade Out animation for a node. Similar to the jQuery fadeOut() function. This
 * is part of the animate methods and returns a Promise. animate() must be called
 * prior to calling this function.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} node
 * @param {Object} nodeDataAnimate
 * @param {boolean} animating
 * @param {number} speed
 * @param {function} ease
 * @param {number} progress
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (node, nodeDataAnimate, animating, animation, speed, ease, progress, options) {
    return new Promise(resolve => {

        // Node is already animating, kill previous animation
        if (animating) {
            Tweener.kill(node, false, { opacity: true });
        }

        // Opacity
        const opacityComputed = (window.getComputedStyle ? getComputedStyle(node) : node.currentStyle).opacity;
        const opacityStyle = getAttrStyle(node, 'opacity');

        // Current data animate
        let dataAnimate = {
            animating: true,
            animation: 'fadeOut',
            opacityStyle: get(nodeDataAnimate, 'opacityStyle', opacityStyle),
            opacityOriginal: (animating ? get(nodeDataAnimate, 'opacityOriginal', opacityComputed) : opacityComputed)
        };
        data(node, 'data-sparkle-animate', dataAnimate);

        // Adjust Speed
        speed *= parseFloat(opacityComputed) / parseFloat(dataAnimate.opacityOriginal);

        // Start Animation
        let tween = Tweener.to(node, speed, {
            opacity: 0,
            ease: ease,
            onStart: () => {

                // On Start
                options.onStart.apply(this, arguments);
            },
            onUpdate: () => {

                // On Update
                options.onUpdate.apply(this, arguments);
            },
            onComplete: () => {

                // Hide Node
                hide(node);

                // On Complete
                options.onComplete.apply(this, arguments);

                // Save data animate
                dataAnimate.animating = false;
                dataAnimate.animation = '';
                data(node, 'data-sparkle-animate', dataAnimate);

                // Reset Animation Values
                node.style.opacity = dataAnimate.opacityStyle;

                // Resolve Promise
                resolve();
            }
        });

        // Tween - Progress
        if (progress > 0) {
            tween.progress(1);
        }
    });
};
