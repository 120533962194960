import isVisible from './isVisible';

/*
 * Is Node Hidden ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 *
 * @returns {boolean}
 */
export default function (node) {
    return !isVisible(node);
}
