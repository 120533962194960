/*
 * Get Default Style for a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {string} tag
 *
 * @returns {Object}
 */
export default function (tag) {

    // Create an iFrame & add to Doc
    var iframe = document.createElement('iframe');
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('width', 0);
    iframe.setAttribute('height', 0);
    document.documentElement.appendChild(iframe);
    var doc = (iframe.contentWindow || iframe.contentDocument).document;

    var testEl = doc.createElement(tag);
    doc.documentElement.appendChild(testEl);

    // Style
    const style = {
        display: (window.getComputedStyle ? getComputedStyle(testEl, null) : testEl.currentStyle).display
    };

    // Remove iFrame
    iframe.parentNode.removeChild(iframe);

    return style;
};
