import setHtml from './setHtml';

/*
 * Empty Html from a Node.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 */
export default function (node) {
    setHtml(node, '');
}
