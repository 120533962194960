import select from '../select/select';

/*
 * Append Node to a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {Node} child
 */
export default function (node, child) {

    // Select matching node
    node = select(node);

    // DOM node exists, Append Child
    if (node) {
        node.appendChild(child);
    }
}
