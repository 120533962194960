"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Test if at least 1 augument is equal to the property
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {any} property
 * @param {*} arguments
 *
 * @returns {boolean}
 */
function default_1(property) {
    var valueFound = false;
    if (arguments.length > 1) {
        var i = 0;
        var length = arguments.length;
        while (++i < length) {
            if (property === arguments[i]) {
                valueFound = true;
                break;
            }
        }
    }
    return valueFound;
}
exports.default = default_1;
