import { removeListener as removeResizeListener } from 'resize-detector'
import getRegistration from './getRegistration';
import getData from '../../data/getData';
import setData from '../../data/setData';
import selectAll from '../../select/selectAll';
import each from '../../../helpers/collection/each';
import isString from '../../../helpers/lang/isString';
import get from '../../../helpers/object/get';
import has from '../../../helpers/object/has';
import unset from '../../../helpers/object/unset';
import endsWith from '../../../helpers/string/endsWith';

/*
 * Remove an event listener to one or several nodes.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 * @requires https://github.com/Justineo/resize-detector for resize events
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {function} listener
 */
export default function (nodes, events, listener) {

    // Remove the event listener from the node
    const removeListener = (node, event, fct) => {
        if (event === 'resize') {
            if (node === window) {
                node.removeEventListener('resize', fct);
            } else {
                removeResizeListener(node, fct)
            }
        } else {
            node.removeEventListener(event, fct, false);
        }
    };

    // Array to save all added registrations
    let registrations = [];

    // Make an array of events in order to remove several events at once
    events = isString(events) ? events.split(' ') : events;

    // Remove event(s) from chosen nodes
    each(selectAll(nodes), node => {

        // If the event is registered, the registration has to be removed
        let nodeEvents = getData(node, 'sparkleEvents') || {};

        // Events are specified, the can be removed one by one
        if (events) {
            each(events, event => {

                // A listener or a registration code is provided
                if (listener) {

                    // Listener is a string which means that it is the registration
                    // name. Therefore the listener can be retrieve from nodes' data
                    let registration;
                    if (isString(listener)) {
                        registration = listener;
                        listener = get(get(nodeEvents, registration,  {}), 'listener', null);
                        if (!listener) {
                            return;
                        }

                    // Find registration from data
                    } else {
                        registration = getRegistration(event, listener);
                    }

                    // Registration exists
                    if (has(nodeEvents, registration)) {

                        // Remove listener from registered events
                        setData(node, 'sparkleEvents', unset(nodeEvents, registration));

                        // Remove Event Listener
                        removeListener(node, event, listener);

                        // Add to registrations
                        registrations.push({
                            node: node,
                            event: event,
                            registration: registration
                        });
                    }

                // Listener nor registration code is provided, therefore all listeners
                // will be removed
                } else {
                    each(nodeEvents, (nodeEvent, registration) => {
                        if (nodeEvent.event === event) {

                            // Remove listener from registered events
                            nodeEvents = unset(nodeEvents, registration);

                            // Remove Event Listener
                            removeListener(node, event, nodeEvent.listener);

                            // Add to registrations
                            registrations.push({
                                node: node,
                                event: event,
                                registration: registration
                            });
                        }
                    });
                    setData(node, 'sparkleEvents', nodeEvents);
                }
            });

        // No event specified, all events will be removed
        } else {

            // Remove events corresponding to registration
            if (listener && isString(listener)) {
                const registration = listener;

                each(nodeEvents, (nodeEvent, nodeRegistration) => {

                    if (endsWith(nodeRegistration, `-${registration}`)) {

                        // Remove listener from registered events
                        setData(node, 'sparkleEvents', unset(nodeEvents, nodeRegistration));

                        removeListener(node, nodeEvent.event, nodeEvent.listener);

                        // Add to registrations
                        registrations.push({
                            node: node,
                            event: nodeEvent.event,
                            registration: nodeRegistration
                        });
                    }
                });

            // Remove all events
            } else {

                each(nodeEvents, (nodeEvent, registration) => {
                    removeListener(node, nodeEvent.event, nodeEvent.listener);

                    // Add to registrations
                    registrations.push({
                        node: node,
                        event: nodeEvent.event,
                        registration: registration
                    });
                });
                setData(node, 'sparkleEvents', {});
            }
        }
    });

    return registrations;
};
