/*
 * Return the Width of the window
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @returns {Object}
 */
export default function () {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
