import position from '../.internal/style/position';
import parent from '../select/parent';
import select from '../select/select';

/*
 * Get the offset position of a node relative to its parent.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 *
 * @returns {Object}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // Parent Node
    const nodeParent = parent(node);

    // Node & Parent Exist
    return node && nodeParent ? position(node, nodeParent) : null;
}
