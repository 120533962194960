import hashCode from '../../../helpers/string/hashCode';
import random from '../../../helpers/string/random';

/*
 * Generate a unique event registration code based on the event, listener or the
 * provided registration
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {string} event
 * @param {function} listener
 * @param {string} registration
 */
export default function (event, listener, registration) {
    return registration ? registration : `${event}-${String(hashCode(String(listener) + event))}-${random(10)}`;
};
