import getData from '../data/getData';
import setData from '../data/setData';
import get from '../../helpers/object/get';
import set from '../../helpers/object/set';
import Sparkle from '../../Sparkle';

/*
 * Enable/Disable scrolling of the body element by applying an overflow = hidden
 * style. Many libraries have side effects. The specified node is used as a
 * reference of the initiator as many elements can request to lock the body
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {boolean} lock
 * @param {Node} node
 */
export default function (lock, node) {

    node = node || document.body;

    const isScrollLocked = getData(node, 'bodyLock');

    // Lock
    if (lock && !isScrollLocked) {
        setData(node, 'bodyLock', true);
        setData(node, 'bodyNativeOverflow', document.body.style.overflow);
        document.body.style.overflow = 'hidden';

    // Unlock
    } else if (!lock && isScrollLocked) {
        document.body.style.overflow = getData(node, 'bodyNativeOverflow');
        setData(node, 'bodyLock', false);
        setData(node, 'bodyNativeOverflow', '');
    }
}
