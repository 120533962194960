"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Return Object Keys as an Array.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Object} obj
 *
 * @returns {Array}
 */
function default_1(obj) {
    return Object.keys(Object(obj));
}
exports.default = default_1;
