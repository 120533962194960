import setData from './setData';
import getData from './getData';
import isUndefined from '../../helpers/lang/isUndefined';

/*
 * Set/Get Data Attribute from a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {string} attribute
 * @param {*} value
 *
 * @returns {*|null|undefined}
 */
export default function (node, attribute, value) {
    return (!isUndefined(value) ? setData(node, attribute, value) : getData(node, attribute));
}
