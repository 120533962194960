import isFunction from '../../helpers/lang/isFunction';

/*
 * Execute a callback function when DOM is loaded & ready
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 *
 * @param {function} callback
 *
 * @returns {Promise}
 */
export default function (callback) {
    return new Promise(resolve => {

        // Loaded
        const loaded = () => {
            resolve();
            if (isFunction(callback)) {
                callback();
            }
        };

        // Listeners
        if (document.readyState !== 'loading') {
            loaded();
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', () => {
                loaded();
            });
        } else {
            document.attachEvent('onreadystatechange', () => {
                if (document.readyState !== 'loading') {
                    loaded();
                }
            });
        }
    });
}
