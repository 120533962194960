import Sparkle from '../../Sparkle';

/*
 * Add hide to Sparkle.methods.defaults.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */

Sparkle.methods.defaults.hide = {
    speed:  400,
    ease: 'Power1.easeOut'
};
