import attr from '../../../../fn/attributes/attr';
import each from '../../../../helpers/collection/each';
import isNull from '../../../../helpers/lang/isNull';

/*
 * Get a Specific Attr Style for a Node.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} node
 * @param {string} attribute
 *
 * @returns {string}
 */
export default function (node, attribute) {

    // Default Value
    let attributeStyle = '';

    const attrStyle = attr(node, 'style');

    // Style have some value
    if (!isNull(attrStyle) && attrStyle !== '') {

        // Parse Attr Styles
        each(attrStyle.split(';'), style => {
            style = style.trim();
            if (style !== '') {
                const styleProps = style.split(':');
                if (styleProps[0].trim() === attribute) {
                    attributeStyle = styleProps[1].trim();
                }
            }
        });
    }

    return attributeStyle;
};
