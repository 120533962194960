import '../.defaults/show';
import animate from './animate';
import onCallback from '../.internal/callback/onCallback';
import getAttr from '../../fn/attributes/getAttr';
import selectAll from '../../fn/select/selectAll';
import each from '../../helpers/collection/each';
import isNull from '../../helpers/lang/isNull';
import merge from '../../helpers/object/merge';
import Sparkle from '../../Sparkle';

/*
 * Show nodes based on the specified transition. Similar to the jQuery animate
 * functions. Possible transitions are: slideIn, slideOut, fadeIn, fadeOut, show,
 * hide.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string|Array} nodes
 * @param {string} transition
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default async function (nodes, transition, options) {
    const that = this;

    // Options
    options = merge(Sparkle.methods.defaults.show, options);

    // Reveal is Complete
    const complete = async () => {
        onCallback.call(that, 'onComplete', options);
    };

    // Nodes do not exist
    if (isNull(nodes)) {
        complete();
        return;
    }

    // Create promises
    const promises = [];

    // Add chosen nodes to Promises
    each(selectAll(nodes), node => {

        // Check transition attribute for node
        transition = getAttr(node, 'data-show:transition') || transition;

        // Slide
        if (transition === 'slide' && options.speed !== 0) {
            promises.push(animate(node, 'slideDown', {
                speed: options.speed,
                ease: options.ease
            }));

        // Fade
        } else if (transition === 'fade' && options.speed !== 0) {
            promises.push(animate(node, 'fadeIn', {
                speed: options.speed,
                ease: options.ease
            }));

        // Show
        } else {
            promises.push(animate(node, 'show'));
        }
    });

    // Once all Promises are resolved
    await Promise.all(promises);
    complete();

    return;
}
