import Sparkle from '../Sparkle';
import data from '../fn/data/data';
import merge from '../helpers/object/merge';
import Touchable from '../utilities/Touchable';

/*
 * Plugin loader for Touchable
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */

(sparkle => {

    /**
     * Main
     *
     * @param {Object} options
     */
    sparkle.plugins.touchable = function (options) {

        // Options
        options = merge(sparkle.plugins.touchable.defaults, options);

        // Init
        if (!data(document, options.selector)) {
            data(document, options.selector, new Touchable(document, options));
        }
    };

    /**
     * Defaults
     */
    sparkle.plugins.touchable.defaults = {
        selector: Touchable.SELECTOR
    };

})(Sparkle);
