/*
 * Main variable for the Sparkle framework. It includes some already defined
 * properties (methods, plugins, storage, twinkle). Each property can be extended
 * in order to make better use of memory.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */

const Sparkle = {

    // Methods
    methods: {
        defaults: {}
    },

    // Plugins
    plugins: {},

    // Storage
    storage: {},

    // Twinkle
    twinkle: {

        // Actions
        actions: {},

        // Routines
        routines: [],

        // Functions
        functions: [],

        // Listeners
        listeners: []
    },

    // No Tree Shake
    noTreeShake: []
};

export default Sparkle;
