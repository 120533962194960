import { WheelGestures } from 'wheel-gestures';
import EventsManager from './EventsManager';
import parseBool from '../helpers/lang/parseBool';
import setProps from '../helpers/object/setProps';

/*
 * Mouse Wheel listener
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const WheelEvents = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {

        // Settings
        axis: WheelEvents.AXIS,
        preventDefault: WheelEvents.PREVENT_DEFAULT
    });

    // Settings
    options.preventDefault = parseBool(options.preventDefault);

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
WheelEvents.prototype.init = function () {
    const that = this;

    // Variables
    let isListeningIntent = false;

    // Fct - Handle Event
    const handleEvent = event => {

        // Stop listening
        isListeningIntent = false;

        // Trigger event
        if (event === 'right') {
            that.events.trigger(that.el, 'onWheelRightIntent');
        } else if (event === 'left') {
            that.events.trigger(that.el, 'onWheelLeftIntent');
        } else if (event === 'up') {
            that.events.trigger(that.el, 'onWheelUpIntent');
        } else if (event === 'down') {
            that.events.trigger(that.el, 'onWheelDownIntent');
        }
    };

    // Initiate the WheelGestures & prevent from scrolling on the X axis
    const wheelGesturesOptions = {
        preventWheelAction: that.options.preventDefault ? that.options.axis : false
    };
    const wheelGestures = WheelGestures(wheelGesturesOptions);
    wheelGestures.observe(that.el);

    // Listener - Wheel
    wheelGestures.on('wheel', e => {

        // Gesture has started
        if (e.isStart) {
            isListeningIntent = true;
            that.events.trigger(that.el, 'onWheelStart');
        }

        // Gesture has ended
        if (e.isEnd) {
            isListeningIntent = false;
            that.events.trigger(that.el, 'onWheelEnd');
        }

        // Listening is on
        if (isListeningIntent) {

            // X axis
            if (that.options.axis === 'x') {
                if (e.axisMovement[0] > 350) {
                    handleEvent('right');
                }
                if (e.axisMovement[0] < -350) {
                    handleEvent('left');
                }

            // Y axis
            } else {
                if (e.axisMovement[1] > 350) {
                    handleEvent('down');
                }
                if (e.axisMovement[1] < -350) {
                    handleEvent('up');
                }
            }
        }
    });
};

/**
 * Destroy
 */
WheelEvents.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
WheelEvents.AXIS = 'x'; // '', x, y
WheelEvents.PREVENT_DEFAULT = true;

export default WheelEvents;
