"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Does String Starts With ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {string} str
 * @param {string} needle
 * @param {number} position
 *
 * @returns {boolean}
 */
function default_1(str, needle, position) {
    return str.substr(!position || position < 0 ? 0 : +position, needle.length) === needle;
}
exports.default = default_1;
