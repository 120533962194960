import isUndefined from '../../helpers/lang/isUndefined';

/*
 * Return the Window Scroll positions (X & Y)
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @returns {Object}
 */
export default function () {
    return {
        x: !isUndefined(window.pageXOffset) ? window.pageXOffset : window.scrollLeft,
        y: !isUndefined(window.pageYOffset) ? window.pageYOffset : window.scrollTop
    };
}
