import getAttrStyle from './.internal/getAttrStyle';
import getDimensionComputed from './.internal/getDimensionComputed';
import getDimensionStyle from './.internal/getDimensionStyle';
import isVisible from '../../../fn/attributes/isVisible';
import show from '../../../fn/attributes/show';
import data from '../../../fn/data/data';
import get from '../../../helpers/object/get';
import Tweener from '../../../utilities/Tweener';

/*
 * Slide Down animation for a node. Similar to the jQuery slideDown() function. This
 * is part of the animate methods and returns a Promise. animate() must be called
 * prior to calling this function.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} node
 * @param {Object} nodeDataAnimate
 * @param {boolean} animating
 * @param {number} speed
 * @param {function} ease
 * @param {number} progress
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (node, nodeDataAnimate, animating, animation, speed, ease, progress, options) {
    return new Promise(resolve => {

        // Node is already animating, kill previous animation
        if (animating) {
            Tweener.kill(node, false, {
                height: true,
                paddingTop: true,
                paddingBottom: true,
                marginTop: true,
                marginBottom: true
            });
        }

        // Dimension
        const dimensionComputed = getDimensionComputed(node);
        const dimensionStyle = getDimensionStyle(node);

        // Current data animate
        let dataAnimate = {
            animating: true,
            animation: 'slideDown',
            dimensionStyle: get(nodeDataAnimate, 'dimensionStyle', dimensionStyle),
            dimensionOriginal: get(nodeDataAnimate, 'dimensionOriginal', dimensionStyle),
            overflowStyle: get(nodeDataAnimate, 'overflowStyle', getAttrStyle(node, 'overflow')),
        };
        data(node, 'data-sparkle-animate', dataAnimate);

        // Height
        const heightStart = animating ? dimensionComputed.height : 0;
        //let heightEnd = dataAnimate.dimensionOriginal.height ? dataAnimate.dimensionOriginal.height : dimensionComputed.height;

        // Adjust Speed if possible
        /*if (heightEnd !== 'auto') {
            speed *= (parseInt(heightEnd, 10) - parseInt(heightStart, 10)) / parseInt(heightEnd, 10);
        }*/

        // If height is "auto", the browser doesn't know the exact height of the
        // node. Tweener behaves strangly with margins & paddings. The workaround
        // shows the node in order to compute the height on the screen
        /*if (heightEnd === 'auto') {
            if (!isVisible(node)) {
                show(node);
            }
            heightEnd = getDimensionComputed(node).height;
        }*/

        // Set Animation Values. For some reason Tweener doesn't behave properly
        // when height is set "auto", which is the case when the element is not
        // visible and the height not specified in the CSS. Therefore animation
        // values have to be set to final ones and the animation is from() instead
        // of to()
        Tweener.set(node, {
            overflow: 'hidden',
            //height: heightEnd
        });

        // Show if not already visible
        if (!isVisible(node)) {
            show(node);
        }

        // Tween
        let tween = Tweener.from(node, speed, {
            height: heightStart,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0,
            ease: ease || 'power0.none',
            onStart: () => {
                options.onStart.apply(this, arguments);
            },
            onUpdate: () => {
                options.onUpdate.apply(this, arguments);
            },
            onComplete: () => {

                // Reset Animation Values
                Tweener.clear(node, ['height', 'paddingTop', 'paddingBottom', 'marginTop', 'marginBottom', 'overflow'])

                // On Complete
                options.onComplete.apply(this, arguments);

                // Save Animate Data
                dataAnimate.animating = false;
                dataAnimate.animation = '';
                delete dataAnimate.dimensionStyle;
                delete dataAnimate.dimensionOriginal;
                delete dataAnimate.overflowStyle;
                data(node, 'data-sparkle-animate', dataAnimate);

                // Resolve Promise
                resolve();
            }
        });

        // Tween - Progress
        if (progress > 0) {
            tween.progress(1);
        }
    });
};
