/*
 * Return the Height of the window
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @returns {Object}
 */
export default function () {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}
