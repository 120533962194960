/*
 * Get a Dimension Computed
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} node
 *
 * @returns {Object}
 */
export default function (node) {

    const styleComputed = (window.getComputedStyle ? getComputedStyle(node) : node.currentStyle);

    const height = styleComputed.height;
    const paddingTop = styleComputed.getPropertyValue('padding-top');
    const paddingBottom = styleComputed.getPropertyValue('padding-bottom');
    const marginTop = styleComputed.getPropertyValue('margin-top');
    const marginBottom = styleComputed.getPropertyValue('margin-bottom');

    return {
        height: height,
        padding: {
            top: paddingTop,
            bottom: paddingBottom
        },
        margin: {
            top: marginTop,
            bottom: marginBottom
        }
    };
};
