import windowHeight from './windowHeight';
import windowWidth from './windowWidth';

/*
 * Return the Dimensions of the window (Width & Height)
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @returns {Object}
 */
export default function () {
    return {
        width: windowWidth(),
        height: windowHeight()
    };
}
