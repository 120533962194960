import { CountUp } from 'countup.js';
import enquire from 'enquire.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import Tweener from './Tweener';
import addClass from '../fn/attributes/addClass';
import hide from '../fn/attributes/hide';
import removeClass from '../fn/attributes/removeClass';
import show from '../fn/attributes/show';
import isMatchMedia from '../fn/browser/isMatchMedia';
import windowHeight from '../fn/browser/windowHeight';
import text from '../fn/manipulation/text';
import parent from '../fn/select/parent';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import outerHeight from '../fn/style/outerHeight';
import position from '../fn/style/position';
import each from '../helpers/collection/each';
import join from '../helpers/collection/join';
import toArray from '../helpers/collection/toArray';
import isArray from '../helpers/lang/isArray';
import isBoolean from '../helpers/lang/isBoolean';
import isFunction from '../helpers/lang/isFunction';
import isObject from '../helpers/lang/isObject';
import isString from '../helpers/lang/isString';
import or from '../helpers/lang/or';
import parseBool from '../helpers/lang/parseBool';
import has from '../helpers/object/has';
import set from '../helpers/object/set';
import setProps from '../helpers/object/setProps';
import unset from '../helpers/object/unset';
import animate from '../methods/animate/animate';
import imageLoaded from '../methods/utils/imageLoaded';

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({
    autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load' // notice "resize" isn't in the list
});

/*
 * Allows to set actions to DOM elements based on the scroll position. Available
 * actions are :
 *  - Count
 *  - Pin
 *  - Tween / Parallax
 *  - Toggle Class
 *  - Toggle Visibility (show, slide, fade)
 *  - Trigger Events
 *  - Smooth
 *
 * Powered by GSAP (https://greensock.com)
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const ScrollVibe = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: ScrollVibe.SELECTOR,

        // Settings
        do: [],
        breakpoints: ScrollVibe.BREAKPOINTS,
        reverse: ScrollVibe.REVERSE,
        reverseScroll: ScrollVibe.REVERSE_SCROLL,
        offset: ScrollVibe.OFFSET,
        duration: ScrollVibe.DURATION,
        speed: ScrollVibe.SPEED,
        speedCount: ScrollVibe.SPEED_COUNT,
        smooth: ScrollVibe.SMOOTH,
        smoothEase: ScrollVibe.SMOOTH_EASE,
        smoothDelta: ScrollVibe.SMOOTH_DELTA,
        smoothUnit: ScrollVibe.SMOOTH_UNIT,
        imageLoaded: ScrollVibe.IMAGE_LOADED,
        invalidate: ScrollVibe.INVALIDATE,
        debug: ScrollVibe.DEBUG
    });

    // Settings
    options = attrOption(options, el, ['trigger', 'target', 'reverse', 'reverseScroll', 'start', 'end', 'offset', 'duration', 'smooth', 'speed', 'speedCount', 'smooth', 'acceleration', 'debug'], options.selector);

    // Smooth scroll ajustments
    options.smoothStart = options.start ? options.start : ScrollVibe.SMOOTH_START;
    options.smoothEnd = options.end ? options.end : ScrollVibe.SMOOTH_END;
    options.smoothAcceleration = options.acceleration ? options.acceleration : ScrollVibe.SMOOTH_ACCELERATION;

    // Options - Start/End
    setProps(options, {
        start: ScrollVibe.START,
        end: ScrollVibe.END
    });

    // Do can be specified as an array of actions or as an object for a single
    // action. It needs to be an array
    options.do = isObject(options.do) && !has(options.do, '0') ? [options.do] : options.do;
    const todos = [];
    each(options.do, todo => {
        if (!(isArray(todo) && todo.length === 0)) {
            todos.push(todo);
        }
    });
    options.do = todos;

    // Smooth can be defined at root level to make it easier to use
    if (options.smooth) {
        options.do.push({ smooth: options.smooth });
        unset(options, 'smooth');
    }

    // Boolean values
    options.reverse = parseBool(options.reverse);
    options.reverseScroll = parseBool(options.reverseScroll);
    options.imageLoaded = parseBool(options.imageLoaded);
    options.invalidate = parseBool(options.invalidate);
    options.debug = parseBool(options.debug);

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
ScrollVibe.prototype.init = function () {
    const that = this;

    that.vibes = [];

    // Parse vibes and create individual triggers for the specified breakpoints
    each(that.options.do, options => {

        // Smooth preset need to be defined
        options.smooth = parseBool(options.smooth) || (isBoolean(options.smooth) && options.smooth) ? {} : options.smooth;
        if (options.smooth) {
            setProps(options.smooth, {
                start: options.start || that.options.smoothStart,
                end: options.end || that.options.smoothEnd,
                ease: that.options.smoothEase,
                acceleration: that.options.smoothAcceleration,
                delta: that.options.smoothDelta,
                unit: that.options.smoothUnit,
                trigger: that.options.trigger ? select(that.options.trigger) : parent(that.el)
            });
            options.smooth.trigger = select(options.smooth.trigger);
        }

        // Options
        setProps(options, {
            breakpoints: that.options.breakpoints,
            trigger: that.options.trigger,
            target: that.options.target,
            start: that.options.start,
            reverse: that.options.reverse,
            reverseScroll: that.options.reverseScroll,
            offset: that.options.offset,
            end: that.options.end,
            duration: that.options.duration,
            speed: that.options.speed,
            speedCount: that.options.speedCount,
            eventProgress: '',
            imageLoaded: that.options.imageLoaded,
            invalidate: that.options.invalidate,
            debug: that.options.debug
        });

        // Boolean values
        options.reverse = parseBool(options.reverse);
        options.imageLoaded = parseBool(options.imageLoaded);
        options.debug = parseBool(options.debug);

        // Variable
        const vibe = {
            isOn: false,
            isActive: true
        };
        that.vibes.push(vibe);

        // Match / Unmatch breakpoints
        enquire.register(join(options.breakpoints, ' , '), {
            match : () => {
                that.onMatch(options, vibe);
            },
            unmatch : () => {
                that.onUnmatch(options, vibe);
            }
        });
    });

    // Listener - Refresh / Handle
    that.events.on(that.el, 'handleRefresh', () => {
        that.refresh();
    });

    // Listener - Kill / Handle
    that.events.on(that.el, 'handleKill', () => {
        that.kill();
    });
};

/**
 * On Match
 *
 * @param {Object} options
 * @param {Object} vibe
 */
ScrollVibe.prototype.onMatch = function (options, vibe) {
    const that = this;

    // Elements
    const elDocument = select(document);
    const elTrigger = options.trigger ? select(options.trigger) : that.el;
    const elTarget = options.target ? selectAll(options.target) : that.el;

    // Fct - On
    const on = () => {
        vibe.isOn = true;
        vibe.status = 'on';
        that.on(options);

        // Reverse is off -> The vibe needs to be deactivated
        if (!options.reverse) {
            vibe.isActive = false;
        }
    };

    // Fct - Off
    const off = () => {
        vibe.isOn = false;
        vibe.status = 'off';
        that.off(options);
    };

    // ScrollTrigger default options
    const triggerOptions = {
        trigger: elTrigger,
        start: options.start,
        onToggle: self => {
            if (self.isActive && vibe.isActive) {
                on();
            } else if (vibe.isActive) {
                off();
            }
        }
    };

    // Start
    if (options.offset) {
        triggerOptions.start = `${options.start}+=${options.offset}`;
    }

    // End
    if (options.end) {
        triggerOptions.end = options.end;
    } else if (options.duration) {
        triggerOptions.end = `+=${options.duration}`;
    } else {
        set(triggerOptions, {
            end: 'bottom top',
            endTrigger: 'html'
        });
    }

    // Smooth will generate a parallax effect based on Smooth properties
    if (options.smooth) {
        set(triggerOptions, {
            trigger: options.smooth.trigger,
            start: options.smooth.start,
            end: options.smooth.end,
            endTrigger: options.smooth.trigger
        });

        // Fct - Get Delta Y. It can be based on Window Height or a fixed pixel
        // value. By default it is 15% of the height of the viewport
        const getDeltaY = () => {
            if (options.unit === 'px') {
                return {
                    from: options.smooth.from ? options.smooth.from : options.smooth.delta,
                    to: options.smooth.to ? options.smooth.to : -options.smooth.delta,
                };
            }

            // Screen percentage. If the screen is in portrait mode, the delta needs to
            // be divided by 2, otherwise the smooth is not so smooth !
            let viewport = windowHeight();
            if (isMatchMedia('(orientation: portrait)')) {
                viewport /= 2;
            }

            // If from or to are specified, the speed for the property is disabled
            return {
                from: options.smooth.from ? viewport * parseInt(options.smooth.from, 10) / 100 : options.smooth.acceleration * viewport * options.smooth.delta / 100,
                to: options.smooth.to ? viewport * parseInt(options.smooth.to, 10) / 100 : -options.smooth.acceleration * viewport * options.smooth.delta / 100,
            };
        };

        options.parallax = {
            y: { from: getDeltaY().from, to: getDeltaY().to, ease: options.smooth.ease }
        };
    }

    // It is possible to specify the element that will trigger the end of the scroll
    if (options.endTrigger) {
        triggerOptions.endTrigger = options.endTrigger;
    }

    // Debug
    if (options.debug) {
        triggerOptions.markers = true;
    }

    // Pin. The element will stay fixed until the end has been reached. By default,
    // if no end is specified, it will stay pinned until the end of the parent has
    // been reached. If 'pinFocus' is specified, following elements will be pushed.
    // If 'pinTarget' is specified, this element will be pushed
    if (options.pin) {

        // Pinned triggers needs to be refreshed ealier than the others because they
        // update the size of the viewport
        triggerOptions.refreshPriority = options.pinFocus ? 2 : 1;

        set(triggerOptions, {
            pin: options.pinTarget || true,
            pinSpacing: options.pinFocus || false
        });
        if (!options.end) {
            set(triggerOptions, {
                end: () => {
                    return `+=${(outerHeight(parent(elTrigger)) - position(elTrigger).top - outerHeight(elTrigger))}`;
                },
                endTrigger: elTrigger
            });
        }
    }

    // Reverse Scroll is active. When a scroll up occurs, the vibe needs to be
    // reverted
    if (options.reverseScroll) {

        // Listener - Scroll Down
        that.events.on(elDocument, 'scrollDownIntent', e => {
            if (vibe.isOn && vibe.status !== 'on') {
                vibe.status = 'on';
                that.on(options);
            }
        });

        // Listener - Scroll Up
        that.events.on(elDocument, 'scrollUpIntent', e => {
            if (vibe.isOn && vibe.status === 'on') {
                vibe.status = 'off';
                that.off(options);
            }
        });
    }

    // Count
    if (options.count) {
        setProps(options.count, {
            from: parseInt(text(elTarget), 10),
            to: parseInt(text(elTarget), 10),
            speed: options.speedCount
        });
        text(elTarget, options.count.from.toString());
    }

    // The position must be invalidated whenever the vibe is refreshed
    triggerOptions.invalidateOnRefresh = options.invalidate;

    // If a parallax effect is defined, the scroll trigger options can directly be
    // assigned to Tweener. In that case, GSAP will take care of the progression
    if (options.parallax) {
        triggerOptions.scrub = true;
        triggerOptions.once = !options.reverse;
        vibe.tween = new Tweener({ scrollTrigger: triggerOptions });
        each(options.parallax, (tween, property) => {
            vibe.tween.addTween(elTarget, property, tween, false, false);
        });

    // If a tween effect is defined, the scroll trigger options can directly be
    // assigned to Tweener. In that case, GSAP will take triggering the animation
    } else if (options.tween) {
        triggerOptions.toggleActions = options.reverse ? 'play reverse play reverse' : 'play none play none';
        vibe.tween = new Tweener({ scrollTrigger: triggerOptions });
        each(options.tween, (tween, property) => {
            vibe.tween.addTween(elTarget, property, tween, false, false);
        });

    // If a progress is defined, the scroll trigger will trigger an event whenever
    // the progression is updated. Then name of the event being
    } else if (options.eventProgress !== '') {
        triggerOptions.scrub = true;
        vibe.tween = new Tweener({ scrollTrigger: triggerOptions })
            .addProgress(elTarget)
            .onUpdate(() => {
                that.events.trigger(elTarget, options.eventProgress, { progress: vibe.tween.getProgress() });
            });

    // Create ScrollTrigger as a standalone entity
    } else {
        vibe.scrollTrigger = ScrollTrigger.create(triggerOptions);
    }

    // Vibe can be refreshed as soon as the target image is fully loaded
    if (options.imageLoaded) {
        each((isArray(elTarget) ? elTarget : [elTarget]), async el => {
            await imageLoaded(elTarget);
            that.refreshVibe(vibe);
        });
    }
};

/**
 * On Unmatch
 *
 * @param {Object} options
 * @param {Object} vibe
 */
ScrollVibe.prototype.onUnmatch = function (options, vibe) {
    const that = this;

    // Elements
    const elTarget = options.target ? selectAll(options.target) : that.el;

    // Kill scrolltrigger & revert to their pre-ScrollTriggered state
    if (vibe.scrollTrigger) {
        vibe.scrollTrigger.kill(true);
    }

    // Kill the & revert to their pre-Tweened state
    if (vibe.tween) {
        vibe.tween.revert();
        vibe.tween.kill();
        if (options.tween) {
            each(options.tween, (tween, property) => {
                Tweener.clear(elTarget, property);
            });
        }
        if (options.parallax) {
            each(options.parallax, (tween, property) => {
                Tweener.clear(elTarget, property);
            });
        }
        if (options.smooth) {
            Tweener.clear(elTarget, 'y');
        }
    }
};

/**
 * On
 *
 * @param {Object} options
 */
ScrollVibe.prototype.on = function (options) {
    const that = this;

    // Elements
    const elTarget = options.target ? selectAll(options.target) : that.el;

    // Class Toggle
    if (options.class) {
        addClass(elTarget, isString(options.class) ? options.class : option.class.class);
    }

    // Event Trigger
    if (options.event) {
        const eventsOn = isString(options.event) ? options.event : options.event.on;
        if (eventsOn) {
            let events = [];
            if (isObject(eventsOn)) {
                events = toArray(eventsOn);
            } else if (isString(eventsOn)) {
                events = eventsOn.split(' ');
            }
            each(events, event => {
                that.events.trigger(elTarget, event);
            });
        }
    }

    // Visibility Toggle
    if (options.visibility) {
        if (options.visibility === 'hide') {
            hide(elTarget);
        } else if (options.visibility === 'show') {
            show(elTarget);
        } else if (or(options.visibility, 'fadeOut', 'fadeIn', 'slideUp', 'slideDown')) {
            animate(elTarget, options.visibility, { speed: options.speed });
        }
    }

    // Count
    if (options.count) {
        const countUp = new CountUp(
            elTarget,
            options.count.to,
            {
                startVal: options.count.from,
                duration: options.count.speed / 1000,
                decimalPlaces: 0,
                decimal: '.',
                separator: '',
                suffix: ''
            }
        );
        countUp.start();
    }

    // Function Trigger
    if (options.fct) {
        const fctOn = isFunction(options.fct) ? options.fct : options.fct.on;
        if (fctOn) {
            fctOn();
        }
    }
};

/**
 * Off
 *
 * @param {Object} options
 */
ScrollVibe.prototype.off = function (options) {
    const that = this;

    // Elements
    const elTarget = options.target ? selectAll(options.target) : that.el;

    // Class Toggle
    if (options.class) {
        removeClass(elTarget, isString(options.class) ? options.class : option.class.class);
    }

    // Event Trigger
    if (options.event && options.event.off) {
        let events = [];
        if (isObject(options.event.off)) {
            events = toArray(options.event.off);
        } else if (isString(options.event.off)) {
            events = options.event.off.split(' ');
        }
        each(events, event => {
            that.events.trigger(elTarget, event);
        });
    }

    // Visibility Toggle
    if (options.visibility) {
        if (options.visibility === 'hide') {
            show(elTarget);
        } else if (options.visibility === 'show') {
            hide(elTarget);
        } else if (or(options.visibility, 'fadeOut', 'fadeIn', 'slideUp', 'slideDown')) {
            let animation;
            switch (options.visibility) {
                case 'fadeOut':
                    animation = 'fadeIn';
                    break;
                case 'fadeIn':
                    animation = 'fadeOut';
                    break;
                case 'slideUp':
                    animation = 'slideDown';
                    break;
                case 'slideDown':
                    animation = 'slideUp';
                    break;
            }
            animate(elTarget, animation, { speed: options.speed });
        }
    }

    // Count
    if (options.count) {
        const countUp = new CountUp(
            elTarget,
            options.count.from,
            {
                startVal: options.count.to,
                duration: options.count.speed / 1000,
                decimalPlaces: 0,
                decimal: '.',
                separator: '',
                suffix: ''
            }
        );
        countUp.start();
    }

    // Function Trigger
    if (options.fct && options.fct.off) {
        options.fct.off();
    }
};

/**
 * Refresh
 */
ScrollVibe.prototype.refresh = function () {
    const that = this;

    // Refresh all scroll triggers from all vibes
    each(that.vibes, vibe => {
        that.refreshVibe(vibe);
    });
};

/**
 * Refresh Vibe
 *
* @param {Object} vibe
 */
ScrollVibe.prototype.refreshVibe = function (vibe) {
    if (vibe.scrollTrigger) {
        vibe.scrollTrigger.refresh();
    }
    if (vibe.tween && vibe.tween.timeline && vibe.tween.timeline.scrollTrigger) {
        vibe.tween.timeline.scrollTrigger.refresh();
    }
};

/**
 * Kill
 *
 * @param {boolean} revert
 */
ScrollVibe.prototype.kill = function (revert = true) {
    const that = this;

    // Refresh all scroll triggers
    each(that.vibes, vibe => {
        if (vibe.scrollTrigger) {
            vibe.scrollTrigger.kill(revert);
        }
        if (vibe.tween && vibe.tween.timeline && vibe.tween.timeline.scrollTrigger) {
            vibe.tween.timeline.scrollTrigger.kill(revert);
            vibe.tween.kill();
        }
    });
};

/**
 * Destroy
 */
ScrollVibe.prototype.destroy = function () {
    this.kill(false);
    this.events.destroy();
};

/**
 * Refresh all ScrollTriggers
 */
ScrollVibe.refresh = function () {
    ScrollTrigger.refresh();
};

/**
 * Kill all ScrollTriggers
 *
 * @param {boolean} revert
 */
ScrollVibe.kill = function (revert = true) {
    ScrollTrigger.kill(revert);
};

/**
 * Indicates whether or not any ScrollTrigger-related scroller is scrolling
 *
 * @returns {boolean}
 */
ScrollVibe.isScrolling = function () {
    return ScrollTrigger.isScrolling();
};

/**
 * Constants
 */
ScrollVibe.SELECTOR = '.js-scrollVibe';
ScrollVibe.BREAKPOINTS = ['all'];
ScrollVibe.DEBUG = false;
ScrollVibe.START = 'top 50%';
ScrollVibe.REVERSE = true;
ScrollVibe.REVERSE_SCROLL = false;
ScrollVibe.OFFSET = null;
ScrollVibe.END = null;
ScrollVibe.DURATION = null;
ScrollVibe.SPEED = 400;
ScrollVibe.SPEED_COUNT = 2000;
ScrollVibe.SMOOTH = false;
ScrollVibe.SMOOTH_ACCELERATION = 1;
ScrollVibe.SMOOTH_START = 'top bottom';
ScrollVibe.SMOOTH_END = 'bottom top';
ScrollVibe.SMOOTH_EASE = 'power0.inOut';
ScrollVibe.SMOOTH_DELTA = 12;
ScrollVibe.SMOOTH_UNIT = 'percent';
ScrollVibe.IMAGE_LOADED = false;
ScrollVibe.INVALIDATE = true;

export default ScrollVibe;
