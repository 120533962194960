import 'arrive';
import Sparkle from '../Sparkle';
import attr from '../fn/attributes/attr';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import merge from '../helpers/object/merge';
import parseJson from '../helpers/string/parseJson';
import dataName from '../helpers/utils/dataName';
import Mousetrack from '../utilities/Mousetrack';
import Plugins from '../utilities/Plugins';

/*
 * Plugin loader for Mousetrack
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */

(sparkle => {

    /**
     * Main
     *
     * @param {Object} options
     * @param {Node} context
     */
    sparkle.plugins.mousetrack = function (options, context) {

        // Options
        options = merge(sparkle.plugins.mousetrack.defaults, options);

        // Init
        const init = el => {
            if (Plugins.canRegister(el, options.selector)) {
                Plugins.register(el, options.selector, new Mousetrack(el, merge(options, parseJson(attr(el, dataName(options.selector))))));
            }
        };

        // Initial Init
        each(selectAll(options.selector, context), el => {
            init(el);
        });

        // Arriving Nodes
        document.arrive(options.selector, function () {
            init(this);
        });
    };

    /**
     * Defaults
     */
    sparkle.plugins.mousetrack.defaults = {
        selector: Mousetrack.SELECTOR
    };

})(Sparkle);
