import exec from '../../../helpers/function/exec';
import isFunction from '../../../helpers/lang/isFunction';
import isUndefined from '../../../helpers/lang/isUndefined';
import has from '../../../helpers/object/has';

/*
 * Callback action from options (onComplete, onFail, onTrue ...).
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {string} onCallback
 * @param {Object} options
 * @param {*} ...arguments
  */
export default function (onCallback, options) {

    // onCallback
    if (!isUndefined(options) && has(options, onCallback) && isFunction(options[onCallback])) {
        exec(options[onCallback].apply(null, Array.prototype.slice.call(arguments, 2)));
    }
};
