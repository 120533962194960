import getText from './getText';
import setText from './setText';
import isNull from '../../helpers/lang/isNull';
import isUndefined from '../../helpers/lang/isUndefined';

/*
 * Set/Get Text from a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {string} txt
 *
 * @returns {string|null|undefined}
 */
export default function (node, txt) {
    return (!isNull(txt) && !isUndefined(txt) ? setText(node, txt) : getText(node));
}
