import attr from '../../../fn/attributes/attr';
import each from '../../../helpers/collection/each';
import isArray from '../../../helpers/lang/isArray';
import isNull from '../../../helpers/lang/isNull';
import set from '../../../helpers/object/set';
import dataName from '../../../helpers/utils/dataName';

/*
 * Set options from attr values.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Object} options
 * @param {Node|string} el
 * @param {Array|string} names
 * @param {string} selector
 *
 * @returns {Object}
 */
export default function (options, el, names, selector) {

    // Names must be an array
    names = isArray(names) ? names : [names];

    // Set Options from Attr Value
    each(names, name => {
        const nameAttr = attr(el, `${dataName(selector)}:${name}`);
        if (!isNull(nameAttr)) {
            set(options, name, nameAttr);
        }
    });

    // Return options
    return options;
};
