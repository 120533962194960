const uid = '_sparkle' + Date.now();

/*
 * Data storage for setData() & getData().
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} node
 */
export default function (node) {
    return (node[uid] = node[uid] || {});
};
