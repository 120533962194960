import 'respimage';
import objectFitImages from 'object-fit-images';

/*
 * A collection of polyfills for responsive images on the web. Responsive images
 * are not well supported by all modern internet browsers. Those polyfills solve
 * most compatibility problems.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */

// Polyfill for Responsive Images
respimage();

// Polyfill for Object Fit
objectFitImages();
