import attrOption from './.internal/options/attrOption';
import DomManager from './DomManager';
import EventsManager from './EventsManager';
import ScrollVibe from './ScrollVibe';
import Tweener from './Tweener';
import addClass from '../fn/attributes/addClass';
import getClasses from '../fn/attributes/getClasses';
import removeClass from '../fn/attributes/removeClass';
import append from '../fn/manipulation/append';
import appendChild from '../fn/manipulation/appendChild';
import create from '../fn/manipulation/create';
import empty from '../fn/manipulation/empty';
import html from '../fn/manipulation/html';
import text from '../fn/manipulation/text';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setProps from '../helpers/object/setProps';
import isHtml from '../helpers/string/isHtml';
import trim from '../helpers/string/trim';
import className from '../helpers/utils/className';
import sleep from '../methods/utils/sleep';

/*
 * Reveal texts in an elegant way.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const TextReveal = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: TextReveal.SELECTOR,

        // Settings
        autostart: TextReveal.AUTOSTART,
        mode: TextReveal.MODE,
        split: TextReveal.SPLIT,
        animation: TextReveal.ANIMATION,
        speedWord: TextReveal.SPEED_WORD,
        speedLetter: TextReveal.SPEED_LETTER,
        lagWord: TextReveal.LAG_WORD,
        lagLetter: TextReveal.LAG_LETTER,
        ease: TextReveal.EASE,
        delay: TextReveal.DELAY,
        reverse: TextReveal.REVERSE,
        start: TextReveal.START,
        debug: TextReveal.DEBUG
    });

    // Settings
    options = attrOption(options, el, ['split', 'animation', 'mode', 'ease', 'delay', 'autostart', 'start', 'trigger', 'reverse', 'debug'], options.selector);
    options.autostart = parseBool(options.autostart);
    options.debug = parseBool(options.debug);
    options.reverse = parseBool(options.reverse);

    // Speed depends on animation type
    options.speed = options.split === 'word' ? options.speedWord : options.speedLetter;
    options.lag = options.split === 'word' ? options.lagWord : options.lagLetter;

    // Set Options from Attr
    options = attrOption(options, el, ['speed', 'lag'], options.selector);

    // Selectors
    that.selectors = {
        animation: `${options.selector}__animation`,
        animationWrapper: `${options.selector}__animation-wrapper`
    };

    // Modifiers
    that.modifiers = {
        vAnimationUp: 'v--animation-up'
    };

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        animations: () => selectAll(that.selectors.animation, el)
    });

    // Init
    if (that.options.autostart) {
        that.init();
    }
};

/**
 * Init
 */
TextReveal.prototype.init = function () {
    const that = this;

    // Hide element
    Tweener.set(that.el, { opacity: 0 });

    // The sentence can include HTML elements. In that case, they need to be treated
    // differently to avoid any problems
    let words = [];
    each([...(new DOMParser().parseFromString(html(that.el), 'text/html')).body.childNodes].map(child => child.outerHTML || child.textContent), fragment => {
        if (fragment === '<br>') {
            words.push('<br>');
        } else if (isHtml(fragment)) {
            const el = new DOMParser().parseFromString(trim(fragment), 'text/html').body.childNodes[0];
            each(text(el).split(' '), wrd => {
                words.push(create('span', { html: wrd, classNames: getClasses(el) }).outerHTML);
            });
        } else {
            words = words.concat(trim(fragment).split(' '));
        }
    });

    // Empty the element & replace with spanned items
    empty(that.el);
    each(words, (word, i) => {

        // Split by word. All spans will be filled with the splitted text with a space
        // added after each span
        if (that.options.split === 'word') {

            // <br> need to be treated differently
            if (word === '<br>') {
                appendChild(that.el, create('br'));
            } else {
                appendChild(that.el, create('span', { classNames: className(that.selectors.animationWrapper) }, create('sub', { html: word, classNames: className(that.selectors.animation) })));
            }

        // Split by letter. For each word a span is created. Within this span, letters
        // are added in order to avoid splitted words
        } else if (that.options.split === 'letter') {

            // <br> need to be treated differently
            if (word === '<br>') {
                appendChild(that.el, create('br'));

            } else if (isHtml(word)) {
                const el = new DOMParser().parseFromString(word, 'text/html').body.childNodes[0];
                const classes = getClasses(el);
                classes.push(className(that.selectors.animationWrapper));

                each(text(el).split(''), letter => {
                    appendChild(that.el, create('span', { classNames: classes }, create('sub', { html: letter, classNames: className(that.selectors.animation) })));
                });

            } else {
                each(word.split(''), letter => {
                    appendChild(that.el, create('span', { classNames: className(that.selectors.animationWrapper) }, create('sub', { html: letter, classNames: className(that.selectors.animation) })));
                });
            }
        }

        if (i < words.length - 1) {
            append(that.el, ' ');
        }
    });

    // Listener - Reveal
    that.events.on(that.el, 'handleReveal', async () => {
        await sleep(that.options.delay);
        that.reveal();
    });

    // Listener - Unreveal
    if (that.options.reverse) {
        that.events.on(that.el, 'handleUnreveal', () => {
            that.unreveal();
        });
    }

    // Mode = Load
    if (that.options.mode === 'load') {
        that.events.trigger(that.el, 'handleReveal');

    // Mode = Scroll
    } else if (that.options.mode === 'scroll') {
        that.vibes.push(new ScrollVibe(that.el, {
            debug: that.options.debug,
            trigger: that.options.trigger ? select(that.options.trigger) : that.el,
            do: {
                start: that.options.start,
                reverse: false,
                event: {
                    on: 'handleReveal',
                    off: 'handleUnreveal'
                }
            }
        }));
    }
};

/**
 * Trigger 'onReveal'
 */
TextReveal.prototype.onReveal = function () {
    const that = this;
    that.events.trigger(that.el, 'onReveal');
};

/**
 * Trigger 'onUnreveal'
 */
TextReveal.prototype.onUnreveal = function () {
    const that = this;
    that.events.trigger(that.el, 'onUnreveal');
};

/**
 * Reveal
 */
TextReveal.prototype.reveal = function () {
    const that = this;

    // Up
    if (that.options.animation === 'up') {
        that.revealUp();

    // Wave
    } else if (that.options.animation === 'wave') {
        that.revealWave();

    // Type
    } else if (that.options.animation === 'type') {
        that.revealType();
    }
};

/**
 * Unreveal
 */
TextReveal.prototype.unreveal = function () {
    const that = this;

    // Up
    if (that.options.animation === 'up') {
        that.unrevealUp();
    }
};

/**
 * Reveal - Up
 */
TextReveal.prototype.revealUp = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        addClass(that.el, that.modifiers.vAnimationUp);
        return;
    };

    // Fct - Reveal
    const reveal = async () => {
        const tween = new Tweener({ speed: that.options.speed, ease: that.options.ease });
        each(that.dom.$('animations'), (el, i) => {
            tween.add(el, {
                y: { from: '100%', to: 0, delay: that.options.lag * i }
            });
        });
        await tween
            .onStart(() => {
                Tweener.set(that.el, { opacity: 1 });
            })
            .play();
        return;
    };

    // Fct - Done
    const done = async () => {
        removeClass(that.el, that.modifiers.vAnimationUp);
        Tweener.clear(that.dom.$('animations'), ['y']);
        that.onReveal();
        return;
    };

    // Init & proceed
    await init();
    await reveal();
    done();
};

/**
 * Reveal - Wave
 */
TextReveal.prototype.revealWave = async function () {
    const that = this;

    // Fct - Reveal
    const reveal = async () => {
        const tween = new Tweener({ speed: that.options.speed, ease: that.options.ease });
        each(that.dom.$('animations'), (el, i) => {
            tween.add(el, {
                scale: { from: 0, to: 1, delay: that.options.lag * i },
                y: { from: '80%', to: 0, delay: that.options.lag * i },
                opacity: { from: 0, to: 1, delay: that.options.lag * i },
            });
        });
        await tween
            .onStart(() => {
                Tweener.set(that.el, { opacity: 1 });
            })
            .play();

        return;
    };

    // Fct - Done
    const done = async () => {
        Tweener.clear(that.dom.$('animations'), ['y', 'scale']);
        that.onReveal();
        return;
    };

    // Init & proceed
    await reveal();
    done();
};

/**
 * Reveal - Type
 */
TextReveal.prototype.revealType = async function () {
    const that = this;

    // Fct - Reveal Animation
    const revealAnimation = async (el, lag) => {
        Tweener.set(el, { opacity: 0 });
        await sleep(lag);
        Tweener.set(el, { opacity: 1 });
        return;
    };

    // Fct - Reveal
    const reveal = async () => {
        const promises = [];
        each(that.dom.$('animations'), (el, i) => {
            promises.push(revealAnimation(el, that.options.lag * i));
        });
        Tweener.set(that.el, { opacity: 1 });
        await Promise.all(promises);
        return;
    };

    // Fct - Done
    const done = async () => {
        that.onReveal();
        return;
    };

    // Init & proceed
    await reveal();
    done();
};

/**
 * Unreveal - Up
 */
TextReveal.prototype.unrevealUp = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        addClass(that.el, that.modifiers.vAnimationUp);
        return;
    };

    // Fct - uneveal
    const unreveal = async () => {
        const tween = new Tweener({ speed: that.options.speed, ease: that.options.ease });
        each(that.dom.$('animations'), (el, i) => {
            tween.add(el, {
                y: { to: '-100%', delay: that.options.lag * (that.dom.$('animations').length - 1 - i) }
            });
        });
        await tween.play();
        return;
    };

    // Fct - Done
    const done = async () => {
        Tweener.set(that.el, { opacity: 0 });
        Tweener.clear(that.dom.$('animations'), ['y']);
        removeClass(that.el, that.modifiers.vAnimationUp);
        that.onUnreveal();
        return;
    };

    // Init & proceed
    await init();
    await unreveal();
    done();
};

/**
 * Destroy
 */
TextReveal.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
TextReveal.SELECTOR = '.js-textReveal';
TextReveal.AUTOSTART = true;
TextReveal.MODE = 'load'; // load, scroll, event
TextReveal.SPLIT = 'word'; // letter, word
TextReveal.ANIMATION = 'up'; // wave, up, type
TextReveal.START = 'top 85%';
TextReveal.SPEED_WORD = 350;
TextReveal.SPEED_LETTER = 250;
TextReveal.LAG_WORD = 50;
TextReveal.LAG_LETTER = 35;
TextReveal.DELAY = 0;
TextReveal.EASE = 'power2.out';
TextReveal.DEBUG = false;
TextReveal.REVERSE = false;

export default TextReveal;
