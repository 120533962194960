import { addListener as addResizeListener } from 'resize-detector'
import getRegistration from './getRegistration';
import getData from '../../data/getData';
import setData from '../../data/setData';
import selectAll from '../../select/selectAll';
import each from '../../../helpers/collection/each';
import isString from '../../../helpers/lang/isString';
import get from '../../../helpers/object/get';
import isEmpty from '../../../helpers/object/isEmpty';
import set from '../../../helpers/object/set';

/*
 * Add an event listener to one or several nodes. Registered Events are stored as
 * data in the node in order to be able to be destroyed witout providing the
 * original `listener`.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @requires https://github.com/WebReflection/dom4 for IE
 * @requires https://github.com/Justineo/resize-detector for resize events
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} events
 * @param {function} listener
 * @param {string} registration
 */
export default function (nodes, events, listener, registration) {

    // Add the event listener to the node
    const addListener = (node, event, fct) => {
        if (event === 'resize') {
            if (node === window) {
                node.addEventListener('resize', fct);
            } else {
                addResizeListener(node, fct)
            }
        } else if (event === 'touchstart') {
            node.addEventListener(event, fct, { passive: true });
        } else {
            node.addEventListener(event, fct, false);
        }
    };

    // Array to save all added registrations
    let registrations = [];

    // Make an array of events in order to add several events at once
    events = isString(events) ? events.split(' ') : events;

    // Add event(s) to chosen node(s)
    each(selectAll(nodes), node => {
        each(events, event => {

            // Unique registration
            const nodeRegistration = getRegistration(event, listener, registration);

            // Events can only be registrered once
            let nodeEvents = getData(node, 'sparkleEvents') || {};
            if (isEmpty(get(nodeEvents, nodeRegistration, {}))) {

                // The event listener needs to be registered and stored into the node's
                // data to be able to delete it at a later date by providing the name
                setData(node, 'sparkleEvents', set(nodeEvents, nodeRegistration, { event: event, listener: listener }));

                // Add Event Listener
                addListener(node, event, listener);

                // Add to registrations
                registrations.push({
                    node: node,
                    event: event,
                    registration: nodeRegistration
                });
            }
        });
    });

    return registrations;
};
