import Sparkle from '../../Sparkle';

/*
 * Add scroll to Sparkle.methods.defaults.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 */

Sparkle.methods.defaults.scroll = {
    speed:  1000,
    ease: 'power2.inOut',
    offset: 0
};
