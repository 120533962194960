import getDataStorage from '../.internal/data/getDataStorage';
import select from '../select/select';
import isUndefined from '../../helpers/lang/isUndefined';

/*
 * Get Data Attribute from a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {string} key
 *
 * @returns {*|null}
 */
export default function (node, key) {

    // Select matching node
    node = select(node);

    // DOM node exists, set attr value
    if (node) {

        // Data Storage
        const c = getDataStorage(node);

        // Return stored data
        if (!isUndefined(c[key])) {
            return c[key];
        }
    }

    return null;
}
