/*
 * When you yield to the main thread, you're giving it an opportunity to handle more
 * important tasks than the ones that are currently queued up. Ideally, you should
 * yield to the main thread whenever you have some crucial user-facing work that
 * needs to execute sooner than if you didn't yield.
 *
 * https://web.dev/optimize-long-tasks
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @returns {Promise}
 */
export default function () {
    return new Promise(resolve => {
        setTimeout(resolve, 0);
    });
}
