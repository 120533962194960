import Snap from 'snapsvg-cjs';
import getAttr from '../fn/attributes/getAttr';
import setAttr from '../fn/attributes/setAttr';
import data from '../fn/data/data';
import select from '../fn/select/select';
import each from '../helpers/collection/each';
import isString from '../helpers/lang/isString';
import copy from '../helpers/object/copy';
import get from '../helpers/object/get';
import has from '../helpers/object/has';
import sleep from '../methods/utils/sleep';

/*
 * Morphin animation engine which is able to animate any SVG path into another path
 *
 * Powered by Snap SVG (http://snapsvg.io)
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @constructor
 *
 * @param {Object} options
 *
 * @returns {this}
 */
const Morph = function (options) {
    const that = this;

    options = options || {};

    // Determine the easing function to be used
    if (options.ease) {
        options.easing = that.ease(options.ease);
    } else {
        options.easing = that.ease('sine.in');
    }

    // Global variables
    that.options = options;

    // Array of morphing animations
    that.animations = [];
    that.controllers = [];

    that.start = 0;
    that.duration = 0;

    return that;
};

/**
 * Add several tweens to the timeline
 *
 * @param {Node|string} el
 * @param {Object} properties
 * @param {boolean} addToEnd
 */
Morph.prototype.add = function (el, properties, addToEnd = false) {
    const that = this;

    const options = copy(that.options);

    // Add the element to the options
    options.el = select(el);

    // A destination element is specified. We need to extract the 'd' property and
    // create the path accordingly
    if (has(properties, 'el')) {
        options.d = getAttr(select(properties.el), 'd');
    }

    // A path is specified
    if (has(properties, 'to')) {
        let pathTo;
        if (isString(properties.to)) {
            pathTo = properties.to;
        } else {
            pathTo = getAttr(properties.to, 'd');
        }
        options.d = pathTo;
    }

    // Speed
    if (has(properties, 'speed')) {
        options.speed = properties.speed;
    }

    // Delay
    options.delay = get(options, 'delay', 0);
    if (has(properties, 'delay')) {
        options.delay = properties.delay;
    }
    if (that.start > 0) {
        options.delay += that.start;
    }

    // Add to End of the latest previously created animation
    if (addToEnd) {
        that.start = that.duration;
        options.delay += that.start;
    }

    // Compute possible new duration
    that.duration = Math.max(that.duration, options.delay + options.speed);

    // Ease
    if (has(properties, 'ease')) {
        options.easing = that.ease(properties.ease);
    }

    // From
    if (has(properties, 'from')) {
        let pathFrom;
        if (isString(properties.from)) {
            pathFrom = properties.from;
        } else {
            pathFrom = getAttr(properties.from, 'd');
        }
        Morph.set(options.el, pathFrom);
    }

    // Add to the animations array
    that.animations.push(options);

    return that;
};

/**
 * Specify the 'onStart' callback
 *
 * @param {function} onStart
 */
Morph.prototype.onStart = function (onStart) {
    const that = this;

    that.options.onStart = onStart;

    return that;
};

/**
 * Specify the 'onComplete' callback
 *
 * @param {function} onComplete
 */
Morph.prototype.onComplete = function (onComplete) {
    const that = this;

    that.options.onComplete = onComplete;

    return that;
};

/**
 * Play the animations
 *
 * @param {function} onComplete
 *
 * @returns {Promise}
 */
Morph.prototype.play = async function (onComplete) {
    const that = this;

    // If a function is specified, it will be executed whenever the tween is complete
    if (onComplete) {
        that.onComplete(onComplete);
    }

    // Fct -> Animate morph
    const animateMorph = async options => {
        await new Promise(resolve => {

            // Create the snap element
            const snappy = Snap(options.el);

            // If the node has been removed from the DOM when the function is being
            // called, the Morph cannot be executed correctly
            if (snappy) {

                // If an animation is already going on, the saved timeout needs to
                // be cleared and the resolve function must ne triggered
                if (data(options.el, 'sparkle-morph-timeout')) {
                    data(options.el, 'sparkle-morph-onComplete')();
                    clearTimeout(data(options.el, 'sparkle-morph-timeout'));
                }

                // For some reason the callback from Snap doesn't always fire, the
                // timeout function is there to replace it !
                const timeout = setTimeout(() => {
                    snappy.stop();
                    setAttr(options.el, 'd', options.d);
                    data(options.el, 'sparkle-morph-onComplete')();
                }, options.speed);
                data(options.el, 'sparkle-morph-timeout', timeout);
                data(options.el, 'sparkle-morph-onComplete', resolve);

                // Animate
                snappy.animate({ d: options.d }, options.speed, options.easing);
            }
        });
        return;
    };

    // Fct -> Animate
    const animate = async options => {
        await sleep(options.delay);
        await animateMorph(options);
        return;
    };

    // Fct -> Done
    const done = async () => {
        if (that.options.onComplete) {
            that.options.onComplete();
        }
        return;
    };

    // Function to be executed before the animations start
    if (that.options.onStart) {
        that.options.onStart();
    }

    // Parallel - All animations start simultaneously
    const animations = [];
    each(that.animations, animation => {
        animations.push(animate(animation));
    });

    await Promise.all(animations);
    await done();
    return;
};

/**
 * Init the animation controllers & pause them straight away
 *
 * @returns {Array}
 */
Morph.prototype.init = function () {
    const that = this;

    each(that.animations, options => {
        const snappy = Snap(options.el);
        each(snappy.animate({ d: options.d }, options.speed, options.easing).anims, controller => {
            controller.pause();
            that.controllers.push(controller);
        });
    });

    return that.controllers;
};

/**
 * Progress into the animation to the given progression
 *
 * @param {number} progress
 */
Morph.prototype.progress = function (progress) {
    const that = this;

    if (that.controllers.length === 0) {
        that.init();
    }

    each(that.controllers, controller => {
        controller.status(progress);
        controller.update();
    });

    return that;
};

/**
 * Set Path for the specified element
 *
 * @param {Node|string} el
 * @param {Node|string} path
 */
Morph.set = function (el, path) {

    if (!isString(path)) {
        path = getAttr(path, 'd');
    }

    setAttr(select(el), 'd', path);
};

/**
 * Override the 'onStart' event callback
 *
 * @param {string} ease
 *
 * @returns {function}
 */
Morph.prototype.ease = function (ease) {

    // Quad
    const easeInQuad = function (n) {
        return Math.pow(n, 2);
    };

    const easeOutQuad = function (n) {
        return -1 * n * (n - 2);
    };

    const easeInOutQuad = function (n) {
        if ((n *= 2) < 1) return 0.5 * Math.pow(n, 2);
        return -0.5 * ((--n) * (n - 2) - 1);
    };

    // Cubic
    const easeInCubic = function (n) {
        return Math.pow(n, 3);
    };

    const easeOutCubic = function (n) {
        return Math.pow(n - 1, 3) + 1;
    };

    const easeInOutCubic = function (n) {
        if ((n *= 2) < 1) return 0.5 * Math.pow(n, 3);
        return 0.5 * (Math.pow(n - 2, 3) + 2);
    };

    // Quart
    const easeInQuart = function (n) {
        return Math.pow(n, 4);
    };

    const easeOutQuart = function (n) {
        return -1 * (Math.pow(n - 1, 4) - 1);
    };

    const easeInOutQuart = function (n) {
        if ((n *= 2) < 1) return 0.5 * Math.pow(n, 4);
        return -0.5 * (Math.pow(n - 2, 4) - 2);
    };

    // Quint
    const easeInQuint = function (n) {
        return Math.pow(n, 5);
    };

    const easeOutQuint = function (n) {
        return Math.pow(n - 1, 5) + 1;
    };

    const easeInOutQuint = function (n) {
        if ((n *= 2) < 1) return 0.5 * Math.pow(n, 5);
        return 0.5 * (Math.pow(n - 2, 5) + 2);
    };

    // Sine
    const easeInSine = function (n) {
        return -1 * Math.cos(n * (Math.PI / 2)) + 1;
    };

    const easeOutSine = function (n) {
        return Math.sin(n * (Math.PI / 2));
    };

    const easeInOutSine = function (n) {
        return -0.5 * (Math.cos(Math.PI * n) - 1);
    };

    // Circ
    const easeInCirc = function (n) {
        return -1 * (Math.sqrt(1 - n * n) - 1);
    };

    const easeOutCirc = function (n) {
        return Math.sqrt(1 - Math.pow(n - 1, 2));
    };

    const easeInOutCirc = function (n) {
        if ((n *= 2) < 1) return -0.5 * (Math.sqrt(1 - n * n) - 1);
        return 0.5 * (Math.sqrt(1 - (n -= 2) * n) + 1);
    };

    // Expo
    const easeInExpo = function (n) {
        return (n === 0) ? 0 : Math.pow(2, 10 * (n - 1));
    };

    const easeOutExpo = function (n) {
        return (n === 1) ? 1 : -Math.pow(2, -10 * n) + 1;
    };

    const easeInOutExpo = function (n) {
        if (n === 0) return 0;
        if (n === 1) return 1;
        if ((n *= 2) < 1) return 0.5 * Math.pow(2, 10 * (n - 1));
        return 0.5 * (-Math.pow(2, -10 * --n) + 2);
    };

    // Elastic
    const easeInElastic = function (n) {
        let s = 1.70158;
        const p = 0.3;
        if (n === 0) return 0;
        if (n === 1) return 1;
        s = p / (2 * Math.PI) * Math.asin(1);
        return -(Math.pow(2, 10 * (n -= 1)) * Math.sin((n - s) * (2 * Math.PI) / p));
    };

    const easeOutElastic = function (n) {
        let s = 1.70158;
        const p = 0.3;
        if (n === 0) return 0;
        if (n === 1) return 1;
        s = p / (2 * Math.PI) * Math.asin(1);
        return Math.pow(2, -10 * n) * Math.sin((n - s) * (2 * Math.PI) / p) + 1;
    };

    const easeInOutElastic = function (n) {
        const p = 0.45;
        const s = p / (2 * Math.PI) * Math.asin(1);
        if (n === 0) return 0;
        if ((n *= 2) === 2) return 1;
        if (n < 1) return -0.5 * (Math.pow(2, 10 * (n -= 1)) * Math.sin((n - s) * (2 * Math.PI) / p));
        return Math.pow(2, -10 * (n -= 1)) * Math.sin((n - s) * (2 * Math.PI) / p) * 0.5 + 1;
    };

    // Back
    const easeInBack = function (n, s) {
        if (s === undefined) s = 1.70158;
        return Math.pow(n, 2) * ((s + 1) * n - s);
    };

    const easeOutBack = function (n, s) {
        if (s === undefined) s = 1.70158;
        return (Math.pow(--n, 2) * ((s + 1) * n + s) + 1);
    };

    const easeInOutBack = function (n, s) {
        if (s === undefined) s = 1.70158;
        if ((n *= 2) < 1) return 0.5 * (Math.pow(n, 2) * (((s *= 1.525) + 1) * n - s));
        return 0.5 * (Math.pow((n -= 2), 2) * (((s *= 1.525) + 1) * n + s) + 2);
    };

    // Bounce
    const easeInBounce = function (n) {
        return 1 - mina.easeOutBounce(1 - n);
    };

    const easeOutBounce = function (n) {
        if (n < (1 / 2.75)) {
            return 7.5625 * n * n;
        }
        if (n < (2 / 2.75)) {
            return 7.5625 * (n -= (1.5 / 2.75)) * n + 0.75;
        }
        if (n < (2.5 / 2.75)) {
            return 7.5625 * (n -= (2.25 / 2.75)) * n + 0.9375;
        }
        return 7.5625 * (n -= (2.625 / 2.75)) * n + 0.984375;
    };

    const easeInOutBounce = function (n) {
        if (n < 0.5) return mina.easeInBounce(n * 2) * 0.5;
        return mina.easeOutBounce(n * 2 - 1) * 0.5 + 1 * 0.5;
    };

    // Return the easing function
    switch (ease) {

        // Quad
        case 'quad.in':
            return easeInQuad;
        case 'quad.out':
            return easeOutQuad;
        case 'quad.inOut':
            return easeInOutQuad;

        // Cubic
        case 'cubic.in':
            return easeInCubic;
        case 'cubic.out':
            return easeOutCubic;
        case 'cubic.inOut':
            return easeInOutCubic;

        // Quart
        case 'quart.in':
            return easeInQuart;
        case 'quart.out':
            return easeOutQuart;
        case 'quart.inOut':
            return easeInOutQuart;

        // Quint
        case 'quint.in':
            return easeInQuint;
        case 'quint.out':
            return easeOutQuint;
        case 'quint.inOut':
            return easeInOutQuint;

        // Sine
        case 'sine.in':
            return easeInSine;
        case 'sine.out':
            return easeOutSine;
        case 'sine.inOut':
            return easeInOutSine;

        // Circ
        case 'circ.in':
            return easeInCirc;
        case 'circ.out':
            return easeOutCirc;
        case 'circ.inOut':
            return easeInOutCirc;

        // Expo
        case 'expo.in':
            return easeInExpo;
        case 'expo.out':
            return easeOutExpo;
        case 'expo.inOut':
            return easeInOutExpo;

        // Elastic
        case 'elastic.in':
            return easeInElastic;
        case 'elastic.out':
            return easeOutElastic;
        case 'elastic.inOut':
            return easeInOutElastic;

        // Back
        case 'back.in':
            return easeInBack;
        case 'back.out':
            return easeOutBack;
        case 'back.inOut':
            return easeInOutBack;

        // Bounce
        case 'bounce.in':
            return easeInBounce;
        case 'bounce.out':
            return easeOutBounce;
        case 'bounce.inOut':
            return easeInOutBounce;

        // Default
        default:
            return easeInSine;
    }
};

export default Morph;
