import select from '../select/select';
import each from '../../helpers/collection/each';

/*
 * Return an array listing all the classes applied to the node.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {Node} context
 *
 * @returns {Array|null}
 */
export default function (node, context) {

    // Select matching node
    node = select(node, context);

    // DOM node exists, get all classes
    if (node) {
        const classes = [];
        each(node.classList, className => {
            classes.push(className);
        });
        return classes;
    }

    return null;
}
