import select from '../select/select';
import isString from '../../helpers/lang/isString';

/*
 * Append Html to a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string} node
 * @param {string} htm
 */
export default function (node, htm) {

    // Select matching node
    node = select(node);

    // DOM node exists, set text value
    if (node && isString(htm)) {
        node.innerHTML += htm;
    }
}
