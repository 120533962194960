import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import scroll from '../methods/browser/scroll';
import setProps from '../helpers/object/setProps';

/*
 * Scroll to a specific elements on click event
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const ScrollTo = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: ScrollTo.SELECTOR,

        // Settings
        speed: ScrollTo.SPEED,
        ease: ScrollTo.EASE,
        offset: ScrollTo.OFFSET
    });

    // Settings
    options = attrOption(options, el, ['speed', 'ease', 'offset', 'to'], options.selector);

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
ScrollTo.prototype.init = function () {
    const that = this;

    // Listener - Click
    that.events.on(that.el, 'click', async e => {
        e.preventDefault();
        await scroll(that.options.to, { speed: that.options.speed, ease: that.options.ease, offset: that.options.offset });
        that.events.trigger(that.el, 'onScroll');
    });
};

/**
 * Destroy
 */
ScrollTo.prototype.destroy = function () {
    this.events.destroy();
};

/**
 * Constants
 */
ScrollTo.SELECTOR = '.js-scrollTo';
ScrollTo.SPEED = 1000;
ScrollTo.EASE = 'power1.inOut';
ScrollTo.OFFSET = 0;

export default ScrollTo;
