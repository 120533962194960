import select from './select';

/*
 * Select the parentNode of a node.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|NodeList|string} node
 * @param {Node|string} context
 *
 * @returns {Node|null}
 */
export default function (node, context) {

    // Select matching node
    node = select(node, context);

    // DOM node exists, get attr value
    return node ? node.parentNode : null;
}
