import attrOption from './.internal/options/attrOption';
import DomManager from './DomManager';
import EventsManager from './EventsManager';
import ScrollVibe from './ScrollVibe';
import Tweener from './Tweener';
import select from '../fn/select/select';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setProps from '../helpers/object/setProps';
import imageLoaded from '../methods/utils/imageLoaded';

/*
 * Reveal images in an elegant way.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const ImageReveal = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setProps(options, {
        selector: ImageReveal.SELECTOR,

        // Settings
        autostart: ImageReveal.AUTOSTART,
        mode: ImageReveal.MODE,
        direction: ImageReveal.DIRECTION,
        scale: ImageReveal.SCALE,
        ease: ImageReveal.EASE,
        speed: ImageReveal.SPEED,
        delay: ImageReveal.DELAY,
        start: ImageReveal.START,
        debug: ImageReveal.DEBUG
    });

    // Settings
    options = attrOption(options, el, ['autostart', 'mode', 'ease', 'speed', 'delay', 'scale', 'direction', 'start', 'trigger', 'debug'], options.selector);
    options.autostart = parseBool(options.autostart);
    options.debug = parseBool(options.debug);

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        wrapper: () => select(`${options.selector}__wrapper`, el),
        image: () => select(`${options.selector}__image`, that.dom.$('wrapper'))
    });

    // Init
    if (that.options.autostart) {
        that.init();
    }
};

/**
 * Init
 */
ImageReveal.prototype.init = async function () {
    const that = this;

    // Listener - Reveal
    that.events.once(that.el, 'handleReveal', () => {
        that.reveal();
    });

    // Mode = Load
    if (that.options.mode === 'load') {
        await imageLoaded(that.el);
        that.events.trigger(that.el, 'handleReveal');

    // Mode = Scroll
    } else if (that.options.mode === 'scroll') {
        that.vibes.push(new ScrollVibe(that.el, {
            debug: that.options.debug,
            trigger: that.options.trigger ? select(that.options.trigger) : that.el,
            reverse: false,
            do: {
                start: that.options.start,
                event: 'handleReveal'
            }
        }));
    }
};

/**
 * Trigger 'onReveal'
 */
ImageReveal.prototype.onReveal = function () {
    const that = this;

    // Trigger event
    that.events.trigger(that.el, 'onReveal');
};

/**
 * Reveal
 */
ImageReveal.prototype.reveal = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        return;
    };

    // Fct - Done
    const done = async () => {
        that.onReveal();
        return;
    };

    // Fct - Image -> Show
    const imageShow = async () => {

        const tween = new Tweener({ speed: that.options.speed, delay: that.options.delay, ease: that.options.ease })
            .onStart(() => {
                Tweener.set([that.dom.$('image'), that.dom.$('wrapper')], { opacity: 1 });
            });

        // Left
        if (that.options.direction === 'left') {
            tween
                .add(that.dom.$('wrapper'), {
                    x: { from: '100%', to: 0 }
                })
                .add(that.dom.$('image'), {
                    x: { from: '-100%', to: 0 },
                    scale: { from: that.options.scale, to: 1 }
                });

        // Bottom
        } else if (that.options.direction === 'bottom') {
            tween
                .add(that.dom.$('wrapper'), {
                    y: { from: '-100%', to: 0 }
                })
                .add(that.dom.$('image'), {
                    y: { from: '100%', to: 0 },
                    scale: { from: that.options.scale, to: 1 }
                });

        // Top
        } else if (that.options.direction === 'top') {
            tween
                .add(that.dom.$('wrapper'), {
                    y: { from: '100%', to: 0 }
                })
                .add(that.dom.$('image'), {
                    y: { from: '-100%', to: 0 },
                    scale: { from: that.options.scale, to: 1 }
                },);

        // Right
        } else {
            tween
                .add(that.dom.$('wrapper'), {
                    x: { from: '-100%', to: 0 }
                })
                .add(that.dom.$('image'), {
                    x: { from: '100%', to: 0 },
                    scale: { from: that.options.scale, to: 1 }
                });
        }

        await tween.play();

        return;
    };

    // Init & proceed
    await init();
    await imageShow();
    done();
};

/**
 * Destroy
 */
ImageReveal.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
ImageReveal.SELECTOR = '.js-imageReveal';
ImageReveal.AUTOSTART = true;
ImageReveal.MODE = 'load';
ImageReveal.DIRECTION = 'right';
ImageReveal.SPEED = 1500;
ImageReveal.EASE = 'power2.in';
ImageReveal.DELAY = 0;
ImageReveal.SCALE = 1;
ImageReveal.START = 'top 80%';
ImageReveal.DEBUG = false;

export default ImageReveal;
