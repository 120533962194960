import html from '@sparkle/js/fn/manipulation/html';
import select from '@sparkle/js/fn/select/select';
import selectAll from '@sparkle/js/fn/select/selectAll';
import each from '@sparkle/js/helpers/collection/each';
import setProps from '@sparkle/js/helpers/object/setProps';
import attrOption from '@sparkle/js/utilities/.internal/options/attrOption';
import DomManager from '@sparkle/js/utilities/DomManager';
import EventsManager from '@sparkle/js/utilities/EventsManager';
import ScrollVibe from '@sparkle/js/utilities/ScrollVibe';
import Tweener from '@sparkle/js/utilities/Tweener';

/*
 * About functionalities
 *
 * @author Christophe Meade
 * @copyright 2021-present Oceanway
 *
 * @param {Node} el
 * @param {Object} options
 */
const About = function (el, options) {
    const that = this;

    // Options
    setProps(options, {
        selector: About.SELECTOR
    });

    // Settings
    options = attrOption(options, el, ['subtitle'], options.selector);

    // Global variables
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.vibes = [];

    // DOM
    that.dom = new DomManager();
    that.dom.set({
        title: () => select(`${options.selector}__title`, el),
        subtitle: () => select(`${options.selector}__subtitle`, el),
        description: () => select(`${options.selector}__description`, el),
        cta: () => select(`${options.selector}__cta`, el),
        lis: () => selectAll(`${options.selector}__li`, el)
    });

    // Init
    that.init();
};

/**
 * Init
 */
About.prototype.init = async function () {
    const that = this;

    // Fct - Init
    const init = async () => {
        that.options.title = html(that.dom.$('subtitle'));
        html(that.dom.$('subtitle'), that.options.subtitle);
        return;
    };

    // Fct - Title -> Animate
    const titleAnimate = async () => {
        await new Tweener({ speed: 350, ease: 'power2.in' })
            .add(that.dom.$('subtitle'), {
                y: { to: '100%' }
            })
            .play();

        html(that.dom.$('subtitle'), that.options.title);
        Tweener.set(that.dom.$('subtitle'), { y: 0 });

        await new Tweener({ speed: 450, ease: 'power2.out' })
            .add(that.dom.$('subtitle'), {
                x: { from: '-100%', to: 0 }
            })
            .play();

        return;
    };

    // Fct - Vibe
    const vibe = async () => {

        that.vibes.push(new ScrollVibe(that.el, {
            start: 'top 70%',
            do: [

                // Title
                {
                    trigger: that.dom.$('title'),
                    reverse: false,
                    start: 'top 70%',
                    fct: {
                        on: () => {
                            titleAnimate();
                        }
                    }
                },

                // Description
                {
                    trigger: that.dom.$('description'),
                    target: that.dom.$('cta'),
                    reverse: false,
                    tween: {
                        y: {
                            speed: 500,
                            from: 30,
                            to: 0,
                            ease: 'power3.out',
                            delay: 250
                        },
                        opacity: {
                            speed: 500,
                            from: 0,
                            to: 1,
                            ease: 'power1.out',
                            delay: 250
                        }
                    }
                }
            ]
        }));

        each(that.dom.$('lis'), (el, i) => {
            that.vibes.push(new ScrollVibe(that.el, {
                start: 'top 70%',
                do: {
                    trigger: that.dom.$('description'),
                    target: el,
                    reverse: false,
                    tween: {
                        x: {
                            speed: 500,
                            from: 25,
                            to: 0,
                            ease: 'power3.out',
                            delay: 250 + i * 100
                        },
                        opacity: {
                            speed: 500,
                            from: 0,
                            to: 1,
                            ease: 'power1.out',
                            delay: 250 + i * 100
                        }
                    }
                }
            }));
        });

        return;
    };

    // Init & Proceed
    await init();
    vibe();
};

/**
 * Destroy
 */
About.prototype.destroy = function () {
    this.dom.destroy();
    this.events.destroy();
    each(this.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
About.SELECTOR = '.js-service';

export default About;
